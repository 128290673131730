import { Component } from "@angular/core";
import { Product } from '@astronautlabs/chassis';

@Component({
    templateUrl: './web-home.component.html',
    styleUrls: [ './web-home.component.scss' ]
})
export class WebHomeComponent {
    constructor(
        readonly product : Product
    ) {

    }
}