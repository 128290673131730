<mat-menu #overflowMenu="matMenu">
    <a mat-menu-item (click)="showAddTask()">
        <mat-icon>add</mat-icon>
        New
    </a>

    <a mat-menu-item (click)="removeTask()" [disabled]="!selectedWorkflow">
        <mat-icon>delete</mat-icon>
        Delete
    </a>

    <a mat-menu-item [disabled]="!selectedWorkflow" (click)="editTask()">
        <mat-icon>edit</mat-icon>
        Settings
    </a>

    <a mat-menu-item [disabled]="!selectedWorkflow || !graphDirty" (click)="saveGraph()">
        <mat-icon>save</mat-icon>
        Save
    </a>

</mat-menu>


<div class="loading" [class.visible]="loading">
    <mat-spinner></mat-spinner>
</div>

<!-- <a (click)="loading = true">LOAD</a>
<a (click)="loading = false">UNLOAD</a> -->

<div class="toolbar task-selector">
    <mat-form-field appearance="outline" style="flex-grow: 1;">
        <mat-label>Workflow</mat-label>
        <mat-select [(value)]="selectedWorkflowID" placeholder="New Workflow">
            <mat-option [value]="workflow.id" *ngFor="let workflow of workflows">{{workflow.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    
    <ng-container *ngIf="selectedWorkflow">
        <a mat-icon-button [disabled]="!selectedWorkflow || !graphDirty" (click)="saveGraph()">
            <mat-icon>save</mat-icon>
        </a>
    </ng-container>

    <a mat-icon-button [matMenuTriggerFor]="overflowMenu">
        <mat-icon>more_vert</mat-icon>
    </a>
</div>

<ng-container *ngIf="!selectedWorkflow">
    Select a workflow above to start.
</ng-container>

<div class="hsplitter">
    <mat-tab-group #tabs *ngIf="selectedWorkflow" [(selectedIndex)]="selectedTabIndex" cdkDropList> 
        <mat-tab label="Graph" *ngIf="selectedLanguage === 'fireflow'">

            <div class="hsplitter">
                <aside [class.visible]="footerVisible">
                    <div class="scroller">
                        <ng-container *ngIf="selectedWorkflow?.errors?.length > 0">
                            <h1>Errors</h1>
                            <div class="error-list">
                                <a href="javascript:;" 
                                    (click)="switchToError(error)" 
                                    class="error" 
                                    [class.warning]="error.type === 'warning'" 
                                    [class.info]="error.type === 'info'" 
                                    *ngFor="let error of selectedWorkflow.errors"
                                    >
                                    {{error.message}}
                                </a>
                            </div>
                        </ng-container>
                    </div>
                </aside>
                <dz-editor 
                    *ngIf="selectedWorkflow"
                    [providers]="providers"
                    [active]="workflowSession && !workflowSession.ended"
                    [readonly]="workflowSession && !workflowSession.ended"
                    [graph]="selectedWorkflow.graph || defaultGraph"
                    [availableNodes]="availableNodes"
                    [optionSources]="dynamicOptionSources"
                    [customPropertyTypes]="customPropertyTypes"
                    [universalPropertySets]="universalPropertySets"
                    [valueTypes]="valueTypes"
                    (graphChanged)="onGraphChanged($event)"
                    (contextChanged)="graphContext = $event"
                    (saveRequested)="saveGraph()"
                    ></dz-editor>
            </div>
        </mat-tab>

        <mat-tab label="Code" #codeTab>
            <div class="editor-container" *ngIf="activeTab === codeTab">
                <ngx-monaco-editor 
                    (onInit)="onEditorInit($event)" 
                    [options]="tsEditorOptions" 
                    [(ngModel)]="selectedWorkflow.code"
                    ></ngx-monaco-editor>
            </div>
        </mat-tab>
        <mat-tab #errorTab label="Errors" *ngIf="selectedWorkflow?.errors?.length > 0">

            <div class="error-list large">
                <mat-form-field appearance="outline" style="width: 100%;">

                    <mat-icon matPrefix>search</mat-icon>

                    <mat-label>
                        Search
                    </mat-label>
    
                    <input type="text" matInput [(ngModel)]="errorSearchQuery" />
                    
                    <button mat-icon-button matSuffix *ngIf="errorSearchQuery" (click)="errorSearchQuery = ''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
        
                <div class="empty" *ngIf="!selectedWorkflow?.errors?.length">
                    <h1>Nothing to show</h1>
                    <p>No errors were detected while compiling the graph for this task.</p>
                </div>

                <ng-container *ngIf="selectedWorkflow?.errors?.length > 0">
                    <ng-container *ngFor="let error of selectedWorkflow.errors | omnisearch: errorSearchQuery">
                        <ng-container *ngIf="error === null">
                            <div class="empty">
                                <h1>No matching errors</h1>
                                <p>Revise your search query or clear the search box to see all errors.</p>

                                <div class="note">
                                    Examples:
                                    <ul>
                                        <li><code>searchterm1 searchterm2 ...</code> -- Rank results on the given search terms</li>
                                        <li><code>/regex.*/ig</code> -- Use a regular expression</li>
                                        <li><code>$.foo.bar searchterm1 ...</code> -- Use a JSONPath expression to perform precise searches</li>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="error !== null">
                            <div class="error" 
                                [class.warning]="error.type === 'warning'" 
                                [class.info]="error.type === 'info'"
                                >
                                <h2><span [innerHTML]="error.message | trustHtml"></span></h2>
                                <ul class="facts">
                                    <li>
                                        <ng-container *ngIf="error.type === 'error' || !error.type">Error</ng-container>
                                        <ng-container *ngIf="error.type === 'warning'">Warning</ng-container>
                                        <ng-container *ngIf="error.type === 'info'">Information</ng-container>
                                    </li>
                                    <li *ngIf="error.code"><code>{{error.code}}</code></li>
                                    <li *ngIf="error.nodeId">Node <code>{{error.nodeId}}</code></li>
                                    <li *ngIf="error.slotId">Slot <code>{{error.nodeId}}</code></li>
                                </ul>
                                <div class="details" [innerHTML]="error.details | trustHtml"></div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </mat-tab>

        <mat-tab label="Status" *ngIf="runnable">
            <ng-container *ngIf="workflowSession">
                <h2>Session</h2>
                <div class="property">
                    <label>ID</label>
                    <code>{{workflowSession.id}}</code>
                </div>
                <div class="property">
                    <label>Command</label>
                    <code>ffmpeg {{workflowSession.ffmpegCommandLine.join(' ')}}</code>
                </div>
                <div class="property">
                    <label>Task</label>
                    {{workflowSession.description.name}} &nbsp; [<code>{{workflowSession.description.id}}</code>]
                </div>
                <div class="property">
                    <label>Status</label>
                    <ng-container *ngIf="getSessionState(workflowSession) === 'error'">
                        Ended with Error
                    </ng-container>
                    <ng-container *ngIf="getSessionState(workflowSession) === 'success'">
                        Completed successfully
                    </ng-container>
                    <ng-container *ngIf="getSessionState(workflowSession) === 'not-started'">
                        Not started
                    </ng-container>
                    <ng-container *ngIf="getSessionState(workflowSession) === 'running'">
                        Running
                    </ng-container>
                </div>
                
                <mat-divider></mat-divider>
                <div class="property multi-line">
                    <label>Logs</label>
                    <pre>{{workflowSession.logs.join("\n")}}</pre>
                </div>
                <mat-divider></mat-divider>
                <div class="property multi-line">
                    <label>Raw</label>
                    <pre>{{workflowSession | json }}</pre>
                </div>
                
            </ng-container>
            <ng-container *ngIf="!workflowSession">
                <div class="empty-session">
                    
                    <ng-container *ngIf="selectedWorkflow.executionType === 'continuous'">
                        <ng-container *ngIf="selectedWorkflow.enabled">
                            <h1>Not Running</h1>
                            <p>This task is enabled. It will start as soon as the proper conditions 
                                are met.</p>
                        </ng-container>
                        <ng-container *ngIf="!selectedWorkflow.enabled">
                            <h1>Disabled</h1>
                            <p>This task is disabled. Check "Enable" above to enable this task.</p>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="selectedWorkflow.executionType === 'on-demand'">
                        <h1>Not running</h1>
                        <p>
                            The task is not running. Choose "Run" above to start it.
                        </p>
                    </ng-container>
                    
                </div>
            </ng-container>
        </mat-tab>

        <mat-tab label="Analysis" *ngIf="selectedWorkflow?.compilation?.debug">

            <div style="margin: 2em 0.5em;">
                <div class="property">
                    <label>Command Line</label>
                    <code *ngIf="selectedWorkflow.commandLine">ffmpeg {{selectedWorkflow.commandLine.join(' ')}}</code>
                    <em *ngIf="!selectedWorkflow.commandLine">Unavailable</em>
                </div>

                <ul class="facts">
                    <li>{{selectedWorkflow.compilation.inputs.length}} inputs</li>
                    <li>{{selectedWorkflow.compilation.outputs.length}} outputs</li>
                </ul>
            </div>

            <mat-form-field appearance="outline" floatLabel="always" style="width: 100%;">
                <mat-label>Step</mat-label>
                <mat-select [(ngModel)]="selectedStageIndex">
                    <mat-option 
                        *ngFor="let stage of selectedWorkflow.compilation.debug.stages; index as i"
                        [value]="i"
                        >{{stage.label}}</mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="selectedWorkflow.compilation.debug.stages[selectedStageIndex]">
                <dz-editor 
                    [graph]="selectedWorkflow.compilation.debug.stages[selectedStageIndex].graph"
                    [readonly]="true"
                    (graphChanged)="saveBackDebugGraph($event)"
                ></dz-editor>
            </ng-container>

        </mat-tab>

        <ng-container *ngIf="runnable">
            <mat-tab>
                <ng-template mat-tab-label>
                  Router
                </ng-template>

                <h1>Dynamic Entries</h1>
                <div class="entry" *ngFor="let entry of entries">
                    <a routerLink="/tasks/{{entry.path.replace('/', '.')}}">{{entry.path}}</a>
                
                    <ng-container *ngIf="entry.watched && entry.available">
                        <span class="tag">Connected</span>
                    </ng-container>
                    <ng-container *ngIf="!entry.watched || !entry.available">
                        <span class="tag" *ngIf="entry.watched">
                            Watched
                        </span>
                        <span class="tag" *ngIf="entry.available">
                            Available
                        </span>
                    </ng-container>
                </div>

                <pre class="raw">{{entries | json}}</pre>
                

                <div class="toolbar">
                    <h1>Static Entries</h1>
                    <div class="spacer"></div>
                    <div class="actions">
                        <a mat-raised-button color="primary" (click)="newInput()">
                            <mat-icon>add</mat-icon>
                            Input
                        </a>
                        &nbsp;
                        <a mat-raised-button color="primary" (click)="newOutput()">
                            <mat-icon>add</mat-icon>
                            Output
                        </a>
                    </div>
                </div>


                <br/>

                <div class="fixed-entry" *ngFor="let fixedEntry of fixedEntries">
                    
                    <a mat-button (click)="editFixedEntry(fixedEntry)" class="overview">
                        <ng-container *ngIf="fixedEntry.type === 'input'">
                            <span class="tag identifier">
                                <label>{{fixedEntry.descriptor.type}}</label>
                                {{getLabelForFixedEntry(fixedEntry)}}
                            </span>
            
                            <mat-icon>arrow_forward</mat-icon>
            
                            <span class="tag identifier">
                                <label>router:</label>
                                {{fixedEntry.path}}
                            </span>
                    
                        </ng-container>
                        <ng-container *ngIf="fixedEntry.type === 'output'">
                            <span class="tag identifier">
                                <label>router:</label>
                                {{fixedEntry.path}}
                            </span>

                            <mat-icon>arrow_forward</mat-icon>

                            <span class="tag identifier">
                                <label>{{fixedEntry.descriptor.type}}</label>
                                {{getLabelForFixedEntry(fixedEntry)}}
                            </span>
                    
                                    
                        </ng-container>
                        
                    </a>
                </div>

                <pre class="raw">{{fixedEntries | json}}</pre>

            </mat-tab>
        </ng-container>
        <ng-container *ngIf="selectedWorkflow.executionType === 'reusable'">
            <mat-tab cdkDrag>
                <ng-template mat-tab-label>
                  Usages
                </ng-template>
                Shows which tasks use this reusable task
            </mat-tab>
        </ng-container>

        <mat-tab cdkDrag #jsonTab>
            <ng-template mat-tab-label>
              JSON
            </ng-template>
            <div class="editor-container" *ngIf="activeTab === jsonTab">
                <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="selectedWorkflowJSON"></ngx-monaco-editor>
            </div>
        </mat-tab>

        <mat-tab cdkDrag #jsonTab>
            <ng-template mat-tab-label>
              Debug
            </ng-template>
            <pre>{{selectedWorkflow.compilation | json}}</pre>
        </mat-tab>
        <mat-tab cdkDrag #jsTab>
            <ng-template mat-tab-label>
              JS
            </ng-template>
            <div class="editor-container" *ngIf="activeTab === jsTab">
                <ngx-monaco-editor 
                    (onInit)="onEditorInit($event)" 
                    [options]="tsEditorOptions" 
                    [(ngModel)]="selectedWorkflow.javascript"
                    ></ngx-monaco-editor>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<div class="toolbar toolbar-facts" *ngIf="selectedWorkflow">
    <ul class="facts">
        <li>
            {{selectedWorkflow.graph?.nodes?.length || 0}} nodes, 
            {{selectedWorkflow.graph?.edges?.length || 0}} edges 
        </li>

        <li *ngIf="selectedWorkflow.errors && selectedWorkflow.errors.length > 0" style="color: maroon;">
            {{selectedWorkflow.errors.length}} 
            <ng-container *ngIf="selectedWorkflow.errors.length == 1">
                error
            </ng-container>
            <ng-container *ngIf="selectedWorkflow.errors.length > 1">
                errors
            </ng-container>
        </li>

        <li>
            <ng-container *ngIf="workflowSession">
                <ng-container *ngIf="workflowSession.ended">
                    Ended
                </ng-container>
                <ng-container *ngIf="!workflowSession.ended">
                    Running
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!workflowSession">
                Not started
            </ng-container>
        </li>
    </ul>

    <div class="spacer"></div>

    <ng-container *ngIf="runnable">
        <ng-container *ngIf="selectedWorkflow.executionType === 'continuous'">
            <mat-checkbox [(ngModel)]="taskEnabled">
                Enabled
            </mat-checkbox>
        </ng-container>
        <ng-container *ngIf="selectedWorkflow.executionType !== 'continuous'">
            <ng-container *ngIf="getSessionState(workflowSession) !== 'not-started'">
                <ng-container *ngIf="getSessionState(workflowSession) === 'running'">
                    Running
                </ng-container>
                <ng-container *ngIf="getSessionState(workflowSession) === 'error'">
                    Error
                </ng-container>
                <ng-container *ngIf="getSessionState(workflowSession) === 'success'">
                    Success
                </ng-container>
            </ng-container>

            &nbsp;

            <ng-container *ngIf="getSessionState(workflowSession) !== 'running'">
                <ng-container *ngIf="['success', 'not-started'].includes(getSessionState(workflowSession))">
                    <button mat-raised-button color="primary" (click)="enableTask()">
                        <mat-icon>play_arrow</mat-icon>
                        Run
                    </button>
                </ng-container>
                <ng-container *ngIf="['error'].includes(getSessionState(workflowSession))">
                    <button mat-raised-button color="primary" (click)="enableTask()">
                        <mat-icon>refresh</mat-icon>
                        Run Again
                    </button>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="getSessionState(workflowSession) === 'running'">
                <button mat-raised-button color="primary" (click)="disableTask()">
                    <mat-icon>stop</mat-icon>
                    Stop
                </button>
            </ng-container>
        </ng-container>
    </ng-container>

</div>
