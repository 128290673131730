<div class="page-container">
    <div class="headline">
        <mat-form-field class="name">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="workflow.name">
        </mat-form-field>
    </div>

    <div class="language-controls" *ngIf="!isPersisted">
        <fieldset>
            <label>Language</label>
            <mat-radio-group [(ngModel)]="workflow.language" class="languages">
                <div class="language-option">
                    <mat-radio-button value="typescript">TypeScript</mat-radio-button>
                    <p>Build this workflow by writing code in TypeScript, a strongly-typed 
                        superset of Javascript.</p>
                </div>
                <div class="language-option">
                    <mat-radio-button value="fireflow">Fireflow <span class="tag">Alpha</span></mat-radio-button>
                    <p>Build this workflow by building graphs in Fireflow, the general 
                        purpose visual programming language.</p>
                </div>
            </mat-radio-group>
        </fieldset>
    </div>

    <div *ngIf="isPersisted">
        <mat-checkbox [(ngModel)]="workflow.enabled">Enabled</mat-checkbox>
    </div>

    <div class="save-toolbar">
        <div class="spacer"></div>
        <a href="javascript:;" mat-button (click)="discard()">
            <ng-container *ngIf="isPersisted">
                Discard Changes
            </ng-container>
            <ng-container *ngIf="!isPersisted">
                Cancel
            </ng-container>
            
        </a>
        <a href="javascript:;" mat-raised-button color="primary" (click)="save()">Save</a>
    </div>
</div>