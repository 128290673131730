import { Injectable } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Config } from './config';
import * as uuid from 'uuid/v4';
import { Router, NavigationStart } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DiazoNodeSet } from 'diazo';

export interface ServiceInfo {
    service : string;
    version : string;
    productName : string;
    companyName : string;
    capabilities : string[];
}

export interface StateNotification {
    type : string;
    data : any;
}

export interface Backend {
    id? : string;
    name : string;
    url : string;
    default : boolean;

    up? : boolean;
    stats? : any;
}

export interface MessageRequest {
    type : string;
}

export interface MessageResponse {
    type : 'response';
    error : boolean;
    code : string;
    message : string;
    request : MessageRequest;
}

export interface StartPreviewResponse extends MessageResponse {
    previewId : string;
    previewUrl : string;
}

export interface FFMPEGCodec {
    id : string;
    name : string;
    supportsDecoding : boolean;
    supportsEncoding : boolean;
    type : 'video' | 'audio' | 'subtitles';
    intraFrameOnly : boolean;
    supportsLossyCompression : boolean;
    supportsLosslessCompression : boolean;
    encoders: string[];
}
  
export class BackendSession {
    constructor(
        readonly backend : Backend,
        readonly matSnackBar : MatSnackBar
    ) {
        this._serviceInfoReady = new Promise<void>(resolve => this._serviceInfoReadyResolve = resolve);
        this.connectToMonitor();
    }

    get id() {
        return this.backend.id;
    }

    async hasCapability(cap : string) {
        await this.serviceInfoReady();
        
        if (!this.serviceInfo || !this.serviceInfo.capabilities)
            return false;

        return this.serviceInfo.capabilities.includes(cap);
    }

    serviceInfo : ServiceInfo;
    monitor : WebSocket;
    connected = false;

    status : any;

    maxStatSamples = 1000;
    cpuUsageData : any[] = [];
    memUsageData : any[] = [];

    _statusChanged = new Subject<any>();

    get statusChanged(): Observable<any> {
        return this._statusChanged;
    }

    get baseUrl() {
        return this.backend.url;
    }

    get monitorUrl() {
        return `${this.baseUrl.replace(/^http/, 'ws')}/monitor`;
    }

    private handlers = new Map<string,(response : any) => void>();

    async sendRequest(request : any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.handlers.set(request.id, response => {
                resolve(response);
            });
            this.monitor.send(JSON.stringify(request));
        });
    }

    async startPreview(input : string, mode : string): Promise<StartPreviewResponse> {
        return await this.sendRequest({
            type: 'startPreview',
            input, 
            mode
        });
    }

    async stopPreview(input : string, mode : string) {
        await this.sendRequest({
            type: 'stopPreview',
            input, 
            mode
        });
    }

    serviceInfoReady() {
        return this._serviceInfoReady;
    }

    private _serviceInfoReady : Promise<void>;
    private _serviceInfoReadyResolve : Function;

    private retryAcquireServiceInfo;
    async acquireServiceInfo() {
        if (this.serviceInfo)
            return;
        
        try {
            let response = await fetch(this.baseUrl);
            if (response.status >= 400)
                throw new Error(`Failed to acquire service info: HTTP ${response.status} ${response.statusText}`);
            
            this.serviceInfo = await response.json();
            this._serviceInfoReadyResolve();

            console.log(`[Backend/${this.backend.name}] Service info acquired:`);
            console.dir(this.serviceInfo);

        } catch (e) {
            console.error(`Failed to acquire service info:`);
            console.error(e);
            clearTimeout(this.retryAcquireServiceInfo);
            this.retryAcquireServiceInfo = setTimeout(() => this.acquireServiceInfo(), 1000);
        }
    }

    connectToMonitor() {
        let dead = false;
        let connected = false;

        let monitor = new WebSocket(this.monitorUrl);
        this.monitor = monitor;

        monitor.addEventListener('open', ev => {
            connected = true;
            this.connected = true;
            console.warn(`Connected to monitor (websockets)`);

            this._notifications.next({
                type: 'connected',
                data: {}
            });

            console.log(`[Backend/${this.backend.name}] Acquiring service info...`);
            this.acquireServiceInfo();

            this.matSnackBar.open(`Connected to playout server '${this.backend.name}'`, undefined, { duration: 5000 });
        });

        monitor.addEventListener('error', ev => {
            if (dead)
                return;
            
            dead = true;
            this.connected = false;

            if (connected) {
                monitor.close();
                console.warn(`[Backend/${this.backend.name}] Received websocket error. Reconnecting in 5 seconds...`);
                setTimeout(() => this.connectToMonitor(), 5*1000);
                this.matSnackBar.open(`An error occurred while communicating with playout server '${this.backend.name}'`, undefined, { duration: 5000 });
            } else {
                monitor.close();
                console.warn(`[Backend/${this.backend.name}] Error while connecting to monitor. Reconnecting in 5 seconds...`);
                setTimeout(() => this.connectToMonitor(), 5*1000);
            }
        });

        monitor.addEventListener('close', ev => {
            if (dead)
                return;
            dead = true;
            this.connected = false;
            this.matSnackBar.open(`Lost connection to playout server '${this.backend.name}'`, undefined, { duration: 5000 });

            console.warn(`Disconnected from monitor. Reconnecting in 5 seconds...`);

            this._notifications.next({
                type: 'disconnected',
                data: {}
            });

            setTimeout(() => this.connectToMonitor(), 5*1000);
        });

        monitor.addEventListener('message', ev => {
            if (dead)
                return;
            
            let message = JSON.parse(ev.data);

            if (message.type === 'notification') {
                let notif = message.notification;
                // console.warn(`Received notification of type ${notif.type}:`);
                // console.dir(notif);

                if (notif.type === 'status') {
                    this.status = notif.data;
                    this._statusChanged.next(this.status);
                    this.cpuUsageData.push({ 
                        name: new Date(), 
                        value: this.status.cpu.load 
                    });

                    while (this.cpuUsageData.length > this.maxStatSamples)
                        this.cpuUsageData.shift();

                    this.memUsageData.push({
                        name: new Date(),
                        value: (this.status.mem.total - this.status.mem.free) / this.status.mem.total * 100
                    });
                    
                    while (this.memUsageData.length > this.maxStatSamples)
                        this.memUsageData.shift();

                    //this.cpuUsageTimestamps.push(new Date().toISOString())
                }

                this._notifications.next(notif);
            } else if (message.type === 'response') {
                let handler = this.handlers.get(message.request.id);
                handler(message);
            } else {
                console.warn(`Unhandled message of type ${message.type}:`);
                console.dir(message);
            }
        });
    }

    private _notifications = new Subject<StateNotification>();

    get notifications(): Observable<StateNotification> {
        return this._notifications;
    }

    async getSettings() {
        let response = await fetch(`${this.baseUrl}/api/config`);
        if (response.status >= 400)
            throw new Error(`Error during getSettings(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async changeSettings(settings : Partial<Config>) {
        let response = await fetch(`${this.baseUrl}/api/config`, {
            method: 'PATCH',
            body: JSON.stringify(settings),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status >= 400)
            throw new Error(`Error during changeSettings(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getRouterEntries() {
        let response = await fetch(`${this.baseUrl}/api/router`);
        if (response.status >= 400)
            throw new Error(`Error during getRouterEntries(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getPipelineNodes(): Promise<DiazoNodeSet[]> {
        let response = await fetch(`${this.baseUrl}/api/pipelines/nodes`);
        if (response.status >= 400)
            throw new Error(`Error during getPipelineNodes(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getWorkflowNodes(): Promise<DiazoNodeSet[]> {
        let response = await fetch(`${this.baseUrl}/api/workflows/nodes`);
        if (response.status >= 400)
            throw new Error(`Error during getWorkflowNodes(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getFixedRouterEntries() {
        let response = await fetch(`${this.baseUrl}/api/router/fixed`);
        if (response.status >= 400)
            throw new Error(`Error during getFixedRouterEntries(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getFixedRouterEntry(id : string) {
        let response = await fetch(`${this.baseUrl}/api/router/fixed/${id}`);
        if (response.status >= 400)
            throw new Error(`Error during getRouterEntries(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getRTMPStats() {
        let response = await fetch(`${this.baseUrl}/api/rtmp/stats`);
        if (response.status >= 400)
            throw new Error(`Error during getRTMPStats(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getTranscodingSessions() {
        let response = await fetch(`${this.baseUrl}/api/pipelines/sessions`);
        if (response.status >= 400)
            throw new Error(`Error during getTranscodingSessions(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getWorkflow(id : string) {
        let response = await fetch(`${this.baseUrl}/api/workflows/${id}`);
        if (response.status >= 400)
            throw new Error(`Error during getWorkflow(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getWorkflows() {
        let response = await fetch(`${this.baseUrl}/api/workflows`);
        if (response.status >= 400)
            throw new Error(`Error during getWorkflows(): ${response.status} ${response.statusText}`);

        return await response.json();
    }
    
    async getPipelines() {
        let response = await fetch(`${this.baseUrl}/api/pipelines`);
        if (response.status >= 400)
            throw new Error(`Error during getPipelines(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getPipeline(id : string) {
        let response = await fetch(`${this.baseUrl}/api/pipelines/${id}`);
        if (response.status >= 400)
            throw new Error(`Error during getPipeline(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async createFixedRouterEntry(entry : any) {
        let response = await fetch(
            `${this.baseUrl}/api/router/fixed`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(entry)
            }
        );

        if (response.status >= 400)
            throw new Error(`Error during createFixedRouterEntry(): ${response.status} ${response.statusText}`);

        return await response.json();
    }
    
    async updateFixedRouterEntry(entry : any, restart = false) {
        if (!entry.id)
            throw new Error(`Cannot update a router entry without an ID`);
        
        let response = await fetch(
            `${this.baseUrl}/api/router/fixed/${entry.id}?restart=${restart ? 1 : 0}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(entry)
            }
        );
         
        if (response.status >= 400)
            throw new Error(`Error during updateFixedRouterEntry(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async createPipeline(pipeline : any) {
        let response = await fetch(
            `${this.baseUrl}/api/pipelines`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pipeline)
            }
        );

        if (response.status >= 400)
            throw new Error(`Error during createPipeline(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getPipelineSession(pipelineId : string) {
        let response = await fetch(
            `${this.baseUrl}/api/pipelines/${pipelineId}/session`
        );
        
        if (response.status === 404)
            return null;
        
        if (response.status >= 400)
            throw new Error(`Error during getPipelineSession(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getSystemInfo(): Promise<any> {
        let response = await fetch(
            `${this.baseUrl}/api`
        );

        if (response.status >= 400)
            throw new Error(`Error during getSystemInfo(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async removePipeline(pipeline : any) {
        if (!pipeline.id)
            throw new Error(`Cannot remove pipeline without an ID`);

        return await this.removePipelineById(pipeline.id);
    }

    async removePipelineById(id : string) {
        
        let response = await fetch(
            `${this.baseUrl}/api/pipelines/${id}`,
            { method: 'DELETE' }
        );
         
        if (response.status >= 400)
            throw new Error(`Error during removePipelineById(${id}): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async removeWorkflow(workflow : any) {
        if (!workflow.id)
            throw new Error(`Cannot remove workflow without an ID`);

        return await this.removeWorkflowById(workflow.id);
    }

    async removeWorkflowById(id : string) {
        
        let response = await fetch(
            `${this.baseUrl}/api/workflows/${id}`,
            { method: 'DELETE' }
        );
         
        if (response.status >= 400)
            throw new Error(`Error during removeWorkflowById(${id}): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getCodecs(): Promise<FFMPEGCodec[]> {
        let response = await fetch(
            `${this.baseUrl}/api/pipelines/codecs`
        );

        if (response.status >= 400)
            throw new Error(`Error during getCodecs(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async updatePipeline(pipeline : any, restart = false) {
        if (!pipeline.id)
            throw new Error(`Cannot update a pipeline without an ID`);
        
        // Strip the properties metadata
        if (pipeline.graph && pipeline.graph.nodes) {
            for (let node of pipeline.graph.nodes)
                delete node.properties;
        }

        let response = await fetch(
            `${this.baseUrl}/api/pipelines/${pipeline.id}?restart=${restart ? 1 : 0}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pipeline)
            }
        );
         
        if (response.status >= 400)
            throw new Error(`Error during updatePipeline(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async createWorkflow(workflow : any): Promise<any> {
        let response = await fetch(
            `${this.baseUrl}/api/workflows`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(workflow)
            }
        );

        if (response.status >= 400)
            throw new Error(`Error during createWorkflow(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async updateWorkflow(workflow : any, restart = false): Promise<any> {
        if (!workflow.id)
            throw new Error(`Cannot update a workflow without an ID`);
        
        // Strip the properties metadata
        if (workflow.graph && workflow.graph.nodes) {
            for (let node of workflow.graph.nodes)
                delete node.properties;
        }

        let response = await fetch(
            `${this.baseUrl}/api/workflows/${workflow.id}?restart=${restart ? 1 : 0}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(workflow)
            }
        );
         
        if (response.status >= 400)
            throw new Error(`Error during updateWorkflow(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async enablePipeline(pipelineId : string) {
        let response = await fetch(
            `${this.baseUrl}/api/pipelines/${pipelineId}/enable`,
            { method: 'POST' }
        );
         
        if (response.status >= 400) {
            let errorObject;
            try {
                errorObject = await response.json();
            } catch (e) {
                console.error(`Caught error while parsing JSON of error response (${response.status}) of enablePipeline(pipelineId)`);
                console.error(e);
                throw new Error(`Error during enablePipeline(): ${response.status} ${response.statusText}`);
            }

            throw errorObject;
        }

        return await response.json();
    }

    async disablePipeline(pipelineId : string) {
        let response = await fetch(
            `${this.baseUrl}/api/pipelines/${pipelineId}/disable`,
            { method: 'POST' }
        );
         
        if (response.status >= 400)
            throw new Error(`Error during disablePipeline(): ${response.status} ${response.statusText}`);

        return await response.json();
    }

    async getRTMPSessions() {
        let response = await fetch(`${this.baseUrl}/api/rtmp/sessions`);
        return await response.json();
    }

    async getRouterState(path : string) {
        let response = await fetch(`${this.baseUrl}/api/router/${path.replace(/^\//g, '')}`);
        return await response.json();
    }

    async getRTMPSession(id : string) {
        let response = await fetch(`${this.baseUrl}/api/rtmp/sessions/${id}`);
        return await response.json();
    }

}

@Injectable()
export class BackendService {
    constructor(
        private router : Router,
        private matSnackBar : MatSnackBar
    ) {
        this.loadBackends();

        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationStart) {
                this._currentSession = null;
                setTimeout(() => this._currentSessionChanged.next(null));
            }
        });

    }

    monitor : WebSocket;
    baseUrl = 'http://localhost:3000';
    monitorUrl = 'ws://localhost:3000/monitor';

    backends : Backend[] = [];

    private _backendsChanged = new BehaviorSubject<Backend[]>([]);

    get backendsChanged() : Observable<Backend[]> {
        return this._backendsChanged;
    }

    loadBackends() {
        let backends = JSON.parse(window.localStorage.getItem(`livefire.config.backends`));
        if (!backends) 
            backends = [];

        this.backends = backends;
        this._backendsChanged.next(this.backends);

        for (let backend of backends) {
            let session = this.getSession(backend);

            if (!session) {
                session = new BackendSession(backend, this.matSnackBar);
                this.backendSessions.set(backend.id, session);
                this._allSessionsChanged.next(this.allSessions());
            }
        }
    }

    get(id : string): Backend {
        return this.backends.find(x => x.id === id);
    }

    getSession(id : string): BackendSession {
        return this.backendSessions.get(id);
    }

    get currentSession() {
        return this._currentSession;
    }

    get currentSessionChanged() {
        return this._currentSessionChanged;
    }

    private _currentSessionChanged = new BehaviorSubject<BackendSession>(null);
    private _currentSession : BackendSession;

    setCurrentSession(id : string) {
        if (!id) {
            this._currentSession = null;
            setTimeout(() => this._currentSessionChanged.next(null));
            return;
        }

        let session = this.getSession(id);
        this._currentSession = session;
        setTimeout(() => this._currentSessionChanged.next(session));
    }

    allSessions() {
        return Array.from(this.backendSessions.values());
    }

    _allSessionsChanged = new BehaviorSubject<BackendSession[]>([]);

    get allSessionsChanged() : Observable<BackendSession[]> {
        return this._allSessionsChanged;
    }

    backendSessions = new Map<string, BackendSession>();

    saveBackends() {
        window.localStorage.setItem('livefire.config.backends', JSON.stringify(this.backends));
    }

    deleteBackend(backend : Backend) {
        this.backends = this.backends.filter(x => x.id !== backend.id);
        this.saveBackends();
    }

    addBackend(backend : Backend) {
        backend.id = uuid();
        this.backends.push(backend);
        this.backendSessions.set(backend.id, new BackendSession(backend, this.matSnackBar));

        setTimeout(() => {
            this._backendsChanged.next(this.backends);
            this._allSessionsChanged.next(this.allSessions());
        });

        this.saveBackends();



        return backend;
    }

    updateBackend(backend : Backend) {

        if (!backend.id)
            throw new Error(`Cannot update backend without an ID`);
        
        let index = this.backends.findIndex(x => x.id === backend.id);

        if (index >= 0)
            this.backends[index] = backend;
        else
            this.backends.push(backend);

        this._backendsChanged.next(this.backends);
        this.saveBackends();

        return backend;
    }
}