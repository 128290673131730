import { Component } from '@angular/core';
import { DiazoNodeContext } from 'diazo';
import { DomSanitizer } from '@angular/platform-browser';
import * as showdown from 'showdown';
import { SubSink } from 'subsink';

@Component({
    template: '<div [innerHTML]="content"></div>',
    styles: [`
    
    ::ng-deep h2 { font-weight: 100; }
    ::ng-deep h3 { font-weight: 100; }
    ::ng-deep h4 { font-weight: 100; }

    `]
})
export class NoteNodeComponent {
    constructor(
        private sanitizer : DomSanitizer,
        private nodeContext : DiazoNodeContext
    ) {
    }

    private subsink = new SubSink();

    ngOnInit() {
        this.subsink.add(this.nodeContext.graph.graphChanged.subscribe(() => {
            this.refreshContent();
        }))
    }

    ngOnDestroy() {
        this.subsink.unsubscribe();
    }

    content : any;
    
    private refreshContent() {
        let html = '';

        if (this.nodeContext && this.nodeContext.state && this.nodeContext.state.data) {            
            let content = this.nodeContext.state.data.content;
            
            let converter = new showdown.Converter();
            html = converter.makeHtml(content);
        }

        this.content = this.sanitizer.bypassSecurityTrustHtml(html);
    }
}