import { Component, ViewChild, QueryList, ViewChildren, OnChanges } from '@angular/core';
import { BackendService, BackendSession, FFMPEGCodec } from '../backend.service';
import { MatDialog } from '@angular/material/dialog';
import { InputEditorComponent } from '../input-editor/input-editor.component';
import { OutputEditorComponent } from '../output-editor/output-editor.component';
import { DiazoGraph, DiazoContext, DiazoPropertySet, DiazoNodeSet, 
    DiazoCustomPropertyType, DiazoNode, DiazoPropertyOption, 
    DiazoPropertyOptionGroup } from 'diazo';
import { SubSink } from 'subsink';
import { WORKFLOW_TYPES } from '@astronautlabs/playout-server-diazo';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ShellService } from '../shell.component';
import { FFMatrixPropertyEditorComponent } from '../ff-matrix-property-editor/ff-matrix-property-editor.component';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { FFCodecEditorComponent } from '../ff-codec-editor/ff-codec-editor.component';
import { BackendSessionRef } from '../backend-session-ref';
import { WorkflowEditorComponent } from '../workflow-editor/workflow-editor.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import type * as its from 'typescript';

declare let ts;

const TYPESCRIPT_URL = 'https://cdnjs.cloudflare.com/ajax/libs/typescript/3.5.3/typescript.min.js';

interface TSElement {
    type : string;
    name : string;
}

interface TSInterface extends TSElement {
    type : 'interface';
    members : TSElement[];
}

interface TSVariable extends TSElement {
    type : 'variable';
    valueType : string;
}

interface TSMember extends TSElement {
    visibility : 'public' | 'private' | 'protected';
    comment : string;
}

interface TSMethod extends TSMember {
    type : 'method';
    parameters : TSMethodParameter[];
    returnType : string;
}

interface TSProperty extends TSMember {
    type : 'property';
    gettable : boolean;
    settable : boolean;
    valueType : string;
}

interface TSMethodParameter {
    name : string;
    valueType : string;
}

class WorkflowLanguageService {
    constructor() {
    }

    lib : string;
    
    private _loadPromise : Promise<void>;

    private loadTypescript() {
        if (this._loadPromise)
            return this._loadPromise;
        
        return this._loadPromise = new Promise((resolve, reject) => {
            let script = document.createElement('script');
            script.src = TYPESCRIPT_URL;
            script.addEventListener('load', () => resolve());

            document.body.appendChild(script);
        });
    }

    makeVariable(sourceFile : its.SourceFile, decl : its.VariableDeclaration): TSVariable {
        let valueType = decl.type.getText(sourceFile);
        let name = decl.name.getText(sourceFile);
        console.log(`Handling ${valueType} named '${name}'...`);
        return { type: 'variable', name, valueType };
    }

    makeInterface(sourceFile : its.SourceFile, decl : its.InterfaceDeclaration): TSInterface {
        let interfaceName = decl.name.getText(sourceFile);

        let iface : TSInterface = { 
            type: 'interface', 
            name: interfaceName,
            members: []
        };

        for (let member of decl.members) {
            let kind = ts.SyntaxKind[member.kind];

            if ([ts.SyntaxKind.IndexSignature, ts.SyntaxKind.ConstructSignature, ts.SyntaxKind.CallSignature].includes(member.kind)) {
                // TODO: represent signatures?
                continue;
            }

            if (!member.name) {
                console.log(`Apparently ${kind} declarations have no name...`);
                debugger;
            }
            
            iface.members.push(this.makeElementFromMember(sourceFile, interfaceName, member))
        }

        return iface;
    }

    makeElementFromMember(sourceFile : its.SourceFile, interfaceName : string, member : its.TypeElement): TSElement {
        if ([ts.SyntaxKind.IndexSignature, ts.SyntaxKind.ConstructSignature, ts.SyntaxKind.CallSignature].includes(member.kind)) {
            // TODO: represent these?
            return null;
        }

        let name = member.name.getText(sourceFile);
        let visibility : string = 'public';
        let readonly = false;
        let constant = false;
        let comment : string = '';

        let modifiers = ts.getCombinedModifierFlags(member);

        if ((modifiers & ts.ModifierFlags.Public) !== 0)
            visibility = 'public';
        else if ((modifiers & ts.ModifierFlags.Private) !== 0)
            visibility = 'private';
        else if ((modifiers & ts.ModifierFlags.Protected) !== 0)
            visibility = 'protected';

        if ((modifiers & ts.ModifierFlags.Readonly) !== 0)
            readonly = true;
            
        if ((modifiers & ts.ModifierFlags.Const) !== 0)
            constant = true;

        let docNodes : its.JSDoc[] = member['jsDoc'];
        if (docNodes && docNodes.length > 0) {
            let docNode = docNodes[0];
            comment = docNode.comment
        }

        if (member.kind === ts.SyntaxKind.MethodSignature) {
            let methodSig = <its.MethodSignature>member;

            let parameters : TSMethodParameter[] = [];

            for (let parm of methodSig.parameters) {
                parameters.push({
                    name: parm.name.getText(sourceFile),
                    valueType: parm.type.getText(sourceFile)
                });
            }

            let returnType = methodSig.type.getText(sourceFile);

            return <TSMethod>{
                type: 'method',
                name,
                comment,
                visibility,
                returnType,
                parameters
            };
        } else if (member.kind === ts.SyntaxKind.PropertySignature) {
            let propSig = <its.PropertySignature>member;

            return <TSProperty>{
                type: 'property',
                name,
                visibility,
                comment,
                gettable: true, // TODO: settable-only properties exist
                settable: !constant && !readonly,
                valueType: propSig.type.getText(sourceFile)
            }

        } else {
            console.error(`UNHANDLED MEMBER: '${name}' of type ${ts.SyntaxKind[member.kind]}`);
            console.dir(member);
        }
    }

    makeElements(sourceFile : its.SourceFile, statement : its.Statement): TSInterface[] {
        let name = ts.SyntaxKind[statement.kind];

        if (statement.kind === ts.SyntaxKind.VariableStatement) {
            // name = 'VariableStatement';
            // let variable = <ts.VariableStatement>statement;
            // for (let decl of variable.declarationList.declarations)
            //     elements.push(this.makeVariable(sourceFile, decl));
            return [];
        }
        
        if (statement.kind === ts.SyntaxKind.InterfaceDeclaration) {
            return [ this.makeInterface(sourceFile, <its.InterfaceDeclaration>statement) ];
        } else {
            console.log(`Unhandled statement: ${name}`);
            return [];
        }
    }

    async getType(name : string) : Promise<TSInterface> {

        await this.loadTypescript();
        
        let response = await fetch('https://raw.githubusercontent.com/microsoft/TypeScript/master/lib/lib.es5.d.ts');
        let lib = await response.text();

        let sourceFile = ts.createSourceFile('defaultLib:es5', lib, ts.ScriptTarget.ES2016);

        console.log("[Typescript] Parsed library:");
        console.dir(sourceFile.statements);

        let unit : Record<string,TSInterface> = {};

        for (let st of sourceFile.statements) {
            let elements = this.makeElements(sourceFile, st);
            for (let element of elements) {
                
                if (element.type === 'interface') {
                    let existing = <TSInterface>unit[element.name];
                    if (existing) {
                        existing.members.push(...element.members);
                    } else {
                        unit[element.name] = element;
                    }
                }
            }
        }

        console.log("UNIT:");
        console.dir(unit);

        return unit[name];
    }
}

@Component({
    templateUrl: './workflow-view.component.html',
    styleUrls: ['./workflow-view.component.scss']
})
export class WorkflowViewComponent implements OnChanges { 
    constructor(
        private backendService : BackendService,
        private matDialog : MatDialog,
        private route : ActivatedRoute,
        private router : Router,
        private location : Location,
        private shell : ShellService,
        private matSnackBar : MatSnackBar
    ) {
    }

    backend : BackendSession;

    ngOnChanges() {
        if (this.tabs && this.tabs._tabs)
            this.activeTab = this.tabs._tabs.toArray()[this.selectedTabIndex];
    }

    get providers() {
        return [
            { provide: BackendSessionRef, useValue: new BackendSessionRef(() => this.backend) }
        ];
    }

    get footerVisible() {
        if (this.selectedWorkflow && this.selectedWorkflow.errors && this.selectedWorkflow.errors.length > 0) {
            return true;
        }

        return false;
    }

    get selectedLanguage() {
        return this.selectedWorkflow.language || 'fireflow';
    }
    
    async onEditorInit(editor : monaco.editor.ICodeEditor) {
        this.monacoLoaded = true;
        console.log(`WORKFLOW-VIEW: EDITOR INITIALIZED`);
        this.fetchApiTypes();

        editor.onDidChangeModelContent(() => {
            setTimeout(() => this.graphDirty = true);
        });

        editor.onKeyDown(ev => {
            if (ev.ctrlKey && ev.keyCode === monaco.KeyCode.KEY_S) {
                this.saveGraph();

                ev.preventDefault();
                ev.stopPropagation();
            }
        });

        ////

        let lang = new WorkflowLanguageService();

        let def = await lang.getType('Date');

        console.log(`WHAT DOES DATE MEAN:`);
        console.dir(def);
    }

    async removeTask() {
        if (!this.selectedWorkflow)
            return;

        if (!confirm(`Are you sure you wish to remove workflow '${this.selectedWorkflow.name}'?`))
            return;

        try {
            await this.backend.removeWorkflowById(this.selectedWorkflowID);
        } catch (e) {
            alert(`Failed to remove workflow: ${e.message}`);
            return;
        }

        this.selectedWorkflowID = null;
        this.fetch();
    }

    dynamicOptionSources : Record<string,Record<string,DiazoPropertyOptionGroup[]>> = {};

    routerGraph : DiazoGraph = {
        nodes: [],
        edges: []
    }

    valueTypes = WORKFLOW_TYPES;

    breakdown : any;

    defaultGraph : DiazoGraph = {
        edges: [],
        nodes: []
    }

    _tabs : MatTabGroup;

    @ViewChild('tabs')
    get tabs() : MatTabGroup {
        return this._tabs;
    }

    set tabs(value) {
        setTimeout(() => this._tabs = value);
    }

    @ViewChild('errorTab')
    errorTab : MatTab;
    
    selectedTabIndex : number = 0;

    activeTab : MatTab;

    // get activeTab() {
    //     if (!this.tabs)
    //         return undefined;
        
    //     let tab = this.tabs._tabs.toArray()[this.selectedTabIndex];

    //     return tab;
    // }

    switchToError(error : any) {
        let index = this.tabs._tabs.toArray().findIndex(x => x === this.errorTab);
        if (index >= 0)
            this.selectedTabIndex = index;
        else
            console.warn(`Failed to find errors tab`);
    }

    graphDirty = false;

    editorOptions = {
        theme: 'vs-dark', 
        language: 'json',
        automaticLayout: true
    };

    tsEditorOptions = {
        theme: 'vs-dark', 
        language: 'typescript',
        automaticLayout: true,
        allowJs: true,
        
        fontFamily: '"Courier New", monospace',
        fontLigatures: true,
        fontSize: '16px',
        module: 'commonjs',
        allowNonTsExtensions: true,
        experimentalDecorators: true,
        target: 'es2016',
        moduleResolution: 'node',
        typeRoots: [ 'inmemory://@types' ],
        minimap: {
            enabled: false
        }
    };

    taskSession : any = null;
    
    get selectedWorkflowJSON() {
        if (!this.selectedWorkflow)
            return null;
        
        return JSON.stringify(this.selectedWorkflow, undefined, 2);
    }

    set selectedWorkflowJSON(value) {
        if (!this.selectedWorkflow)
            return;
        
        Object.assign(this.selectedWorkflow, JSON.parse(value));
    }

    updateWorkflow(workflow : any) {
        let index = this.workflows.findIndex(x => x.id === workflow.id);
        if (index >= 0) {
            this.workflows[index] = workflow;
        } else {
            this.workflows.push(workflow);
        }
    }

    get runnable() {
        return this.selectedWorkflow && ['continuous', 'on-demand'].includes(this.selectedWorkflow.executionType);
    }

    async runTask() {

    }

    async enableTask() {
        // if (!this.selectedTask)
        //     return;
        
        // try {
        //     let updatedTask = await this.backend.enableTranscodingTask(this.selectedTask.id);
        //     this.updateTask(updatedTask);
        // } catch (e) {

        //     alert(`Failed to enable task: [${e.error}] ${e.message}`);

        //     return;
        // }
        
        // this.fetch();
    }

    async disableTask() {
        // if (!this.selectedTask)
        //     return;
        
        // this.updateTask(await this.backend.disableTranscodingTask(this.selectedTask.id));
        // this.fetch();
    }

    async editTask() {
        if (!this.selectedWorkflowID)
            return;
        
        let ref = this.matDialog.open(WorkflowEditorComponent, {
            data: {
                serverID: this.backend.id,
                id: this.selectedWorkflowID
            }
        });

        await ref.afterClosed().toPromise();

        this.loading = true;
        this.fetch();
        this.loading = false;

    }

    loading = false;

    get taskEnabled() {
        if (!this.selectedWorkflow)
            return false;

        return this.selectedWorkflow.enabled;
    }

    set taskEnabled(value) {
        if (value == this.selectedWorkflow.enabled)
            return;
        
        if (value) {
            this.enableTask();
        } else {
            this.disableTask();
        }
    }

    async saveGraph() {
        if (!this.selectedWorkflow) {
            return;
        }

        if (!this.graphDirty) {
            this.matSnackBar.open("Workflow already saved.", undefined, {
                duration: 3000
            });
            return;
        }

        this.ignoreGraphChange = true;
        this.updateWorkflow(await this.backend.updateWorkflow(this.selectedWorkflow, false));
        
        setTimeout(() => this.graphDirty = false);

        this.matSnackBar.open("Workflow saved.", undefined, {
            duration: 3000
        });
    }

    ignoreGraphChange = false;

    onGraphChanged($event : DiazoGraph) {
        if (!$event || !this.selectedWorkflow)
            return;

        if (this.ignoreGraphChange) {
            this.ignoreGraphChange = false;
            return;
        }

        setTimeout(() => {        
            for (let edge of $event.edges)
                edge.valid = this.graphContext.isValid(edge);

            this.graphDirty = true;
            this.selectedWorkflow.graph = $event;
        });
    }

    async showAddTask() {
        let ref = this.matDialog.open(WorkflowEditorComponent, {
            data: {
                serverID: this.backend.id,
                id: 'new'
            }
        });

        let cmp = ref.componentInstance;

        await ref.afterClosed().toPromise();
        this.fetch();

        if (cmp.savedWorkflow)
            this.selectedWorkflowID = cmp.savedWorkflow.id;
    }

    selectedStageIndex = 0;

    private _selectedTaskID : string = null;

    get selectedWorkflowID() : string {
        return this._selectedTaskID;
    }

    set selectedWorkflowID(value) {
        this._selectedTaskID = value;
        this.selectedTabIndex = 0;
        this.location.replaceState(`/servers/${this.backend.id}/workflows/${value || 'list'}`);
        this.updateNodes();
        setTimeout(() => this.selectedTabIndex = 0, 200);
    }

    get selectedWorkflow() {
        if (!this.selectedWorkflowID)
            return null;

        if (!this.workflows)
            return null;
        
        return this.workflows.find(x => x.id === this.selectedWorkflowID);
    }

    availableNodes : DiazoNodeSet[] = [];
    customPropertyTypes : DiazoCustomPropertyType[] = [];

    saveBackDebugGraph(graph : DiazoGraph) {
        if (!graph)
            return;
        
        this.selectedWorkflow.compilation.debug.stages[this.selectedStageIndex].graph = graph;
    }

    async updateNodes() {
        let nodes : DiazoNodeSet[] = [];
        
        if (this.selectedWorkflow) {
            if (this.selectedWorkflow.executionType === 'reusable') {

                let slotNodes : DiazoNode[] = [];

                if (this.selectedWorkflow.slots) {
                    for (let slot of this.selectedWorkflow.slots) {
                        slotNodes.push({
                            label: `(${slot.type === 'input' ? 'Input' : 'Output'}) ${slot.label}`,
                            data: {
                                type: slot.type,
                                unit: 'slot',
                                slotId: slot.id
                            },
                            slots: [
                                {
                                    id: slot.id,
                                    type: slot.type === 'input' ? 'output' : 'input',
                                    label: slot.label,
                                    value: slot.value
                                }
                            ]
                        })
                    }
                }

                nodes.push({
                    label: 'I/O',
                    nodes: slotNodes
                })
            }
        }

        // Add local set

        if (this.workflows) {
            let localSet : DiazoNodeSet = {
                id: 'local',
                tags: [],
                label: 'Local',
                nodes: []
            }

            for (let task of this.workflows.filter(x => x.executionType === 'reusable')) {

                // Do not allow recursion.
                if (task.id === this.selectedWorkflowID)
                    continue;

                let taskType = 'filter';
                let takesInput = task.slots.some(x => x.type === 'input');
                let givesOutput = task.slots.some(x => x.type === 'output');

                if (takesInput && givesOutput) {
                    taskType = 'filter';
                } else if (takesInput) {
                    taskType = 'output';
                } else {
                    taskType = 'input';
                }

                localSet.nodes.push({
                    data: {
                        type: taskType,
                        unit: `task`,
                        taskId: task.id
                    },
                    slots: task.slots,
                    label: task.name
                });
            }

            if (localSet.nodes.length > 0)
                nodes = nodes.concat(localSet);
        }

        let serverNodes = await this.backend.getWorkflowNodes()
        nodes = nodes.concat(serverNodes);

        this.availableNodes = nodes;

        this.customPropertyTypes = [
            { namespace: 'livefire', id: 'ffmatrix', component: FFMatrixPropertyEditorComponent },
            { namespace: 'livefire', id: 'ffcodec_audio', component: FFCodecEditorComponent },
            { namespace: 'livefire', id: 'ffcodec_video', component: FFCodecEditorComponent },
            { namespace: 'livefire', id: 'ffcodec', component: FFCodecEditorComponent }
        ]

        this.updateSources();
    }

    async updateSources() {
        this.dynamicOptionSources = {
            twitchChannels: {
                default: [
                    {
                        label: 'Twitch Channel',
                        options: [
                            { label: 'rezonant', value: 'rezonant' },
                            { label: 'astronautlabs', value: 'astronautlabs' },
                        ]
                    }
                ]
            }
        };
    }

    getSessionState(session : any) {
        if (!session)
            return 'not-started';
        
        if (session.ended && session.error) {
            return 'error';
        }

        if (session.ended)
            return 'success';

        return 'running';
    }

    entries : any[];
    fixedEntries : any[];
    graphContext : DiazoContext;

    refreshInterval;

    subsink = new SubSink();

    monacoApiRef : monaco.IDisposable;
    monacoLoaded = false;

    async fetchApiTypes() {
        if (this.monacoApiRef) {
            this.monacoApiRef.dispose();
            this.monacoApiRef = null;
        }

        if (!this.monacoLoaded)
            return;

        let url = `${this.backend.baseUrl}/api/workflows/types.d.ts`;

        console.log(`Fetching types for @astronautlabs/livefire from ${url}...`);
        
        let response = await fetch(url);
        let types = await response.text();

        console.log(`Installing types for @astronautlabs/livefire...`);
        this.monacoApiRef = monaco.languages.typescript.typescriptDefaults.addExtraLib(
          `
          declare module "@astronautlabs/livefire" {
              ${types}
          }
          `, 
          url //`inmemory:///@types/@astronautlabs/livefire.d.ts`
        );
        
    }

    async ngOnInit() {
        this.shell.title = `Workflows`;

        this.route.paramMap.subscribe(params => {
            let id = params.get('id');
            let serverID = params.get('server');

            this.backend = this.backendService.getSession(serverID);
            this.fetchApiTypes();


            this.fetch();

            if (id === 'list')
                this.selectedWorkflowID = null;
            else
                this.selectedWorkflowID = id;

        });

        //this.refreshInterval = setTimeout(() => this.fetch(), 1000);

        this.subsink.add(this.backend.notifications.subscribe(notif => {
            if (notif.type === 'workflow:updated' || notif.type === 'workflow:added') {
                let workflow : any = notif.data.workflow;
                let index = this.workflows.findIndex(x => x.id === workflow.id);

                console.warn(`Received update for workflow ${workflow.id}:`);
                console.dir(workflow);

                if (workflow.running) {
                    let graph : DiazoGraph = workflow.graph;

                    for (let edge of graph.edges) {
                        edge.active = true;
                    }
                }

                if (index >= 0) {
                    this.workflows[index] = workflow;
                } else {
                    this.workflows.push(workflow);
                }
            } else if (notif.type === 'workflow:removed') {
                let task : any = notif.data.task;
                let index = this.workflows.findIndex(x => x.id === task.id);

                if (index >= 0)
                    this.workflows.splice(index, 1);
            }
        }));
    }

    async ngOnDestroy() {
        this.subsink.unsubscribe();
        clearInterval(this.refreshInterval);

        if (this.monacoApiRef) {
            this.monacoApiRef.dispose();
        }
    }

    universalPropertySets : DiazoPropertySet[] = [
        {
            id: 'general',
            label: 'General',
            description: 'Common properties',
            properties: [
                {
                    label: 'Label',
                    description: '',
                    path: '$.label',
                    type: 'text'
                },
                {
                    label: 'Style',
                    path: 'style',
                    type: 'select',
                    options: [
                        { value: 'standard', label: 'Standard' },
                        { value: 'compact', label: 'Compact' },
                        { value: 'compact', label: 'Inline' }
                    ]
                },
                {
                    label: 'Profile',
                    path: 'profile',
                    type: 'select',
                    options: [
                        { value: 'normal', label: 'Normal' },
                        { value: 'slim', label: 'Slim' },
                    ]
                },
                {
                    label: 'Unit',
                    description: '',
                    readonly: true,
                    path: 'data.unit',
                    type: 'text'
                },
                {
                    label: 'Position',
                    description: '',
                    readonly: true,
                    type: 'position'
                },
                {
                    label: 'ID',
                    description: '',
                    readonly: true,
                    path: 'id',
                    type: 'text'
                },
            ]
        }
    ];

    workflows : any[] = [];
    
    async fetch() {
        this.workflows = await this.backend.getWorkflows();
        await this.updateNodes();
    }

    newInput() {
        let ref = this.matDialog.open(InputEditorComponent, { data: { id: 'new' }});
        ref.afterClosed().subscribe(() => this.fetch());
    }

    getLabelForFixedEntry(entry) {
        if (entry.type === 'input') {
            
            if (entry.descriptor.type === 'rtmp') {
                return entry.descriptor.edge;
            } else if (entry.descriptor.type === 'udp') {
                return entry.descriptor.port;
            } else if (entry.descriptor.type === 'http') {
                return entry.descriptor.url;
            } else if (entry.descriptor.type === 'file') {
                return entry.descriptor.filename;
            }

        } else if (entry.type === 'output') {
            return entry.descriptor.endpoint;
        }

        return '?/?'
    }

    newOutput() {
        let ref = this.matDialog.open(OutputEditorComponent, { 
            data: { id: 'new' },
        });

        ref.afterClosed().subscribe(() => this.fetch());
    }

    editInput(id : string) {
        let ref = this.matDialog.open(InputEditorComponent, { data: { id }});
        ref.afterClosed().subscribe(() => this.fetch());
    }

    editOutput(id : string) {
        let ref = this.matDialog.open(OutputEditorComponent, { data: { id }});
        ref.afterClosed().subscribe(() => this.fetch());
    }

    editFixedEntry(fixedEntry : any) {
        if (fixedEntry.type === 'input')
            this.editInput(fixedEntry.id);
        else
            this.editOutput(fixedEntry.id);
    }
}