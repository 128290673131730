
<h1>New Cluster Node</h1>

<p>To add a new cluster node, download the cluster agent and run it on the 
    node you wish to register. The cluster agent executable is preconfigured 
    to connect to the current playout server. The cluster node will 
    automatically appear in LiveFire once a connection is made.</p>

<p>In order for the cluster node to successfully connect, it must be able to
    contact the playout server websockets service. Ensure that the URL below 
    is accessible from the desired cluster node. You can adjust the cluster 
    registration URL for this playout server in Settings.</p>

<mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Registration URL</mat-label>
    <input type="text" matInput [readonly]="true" [ngModel]="registrationUrl" />
</mat-form-field>

<p></p>

<div style="text-align: center;">
    <a mat-raised-button color="primary" target="_blank" href="/path/to/agent">
        <mat-icon>get_app</mat-icon>
        Download Agent
    </a>
    &nbsp;
    <a mat-raised-button (click)="close()">Close</a>
</div>