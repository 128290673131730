import { DiazoPropertyContext, MULTIPLE_VALUES } from 'diazo';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { BackendSessionRef } from '../backend-session-ref';
import { PipelinesService } from '../pipelines.service';
import { BackendService, FFMPEGCodec } from '../backend.service';

export interface FFMatrixPropertyOptions {
    squareSizes? : number[];
    minRowSize? : number;
    maxRowSize? : number;
    maxColumnSize? : number;
    minColumnSize? : number;
    matrixTypePath : string;
}

@Component({
    templateUrl: `./ff-codec-editor.component.html`,
    styleUrls: [`./ff-codec-editor.component.scss`],
})
export class FFCodecEditorComponent {
    constructor(
        private context : DiazoPropertyContext,
        private sessionRef : BackendSessionRef
    ) {
        let self = this;
        this.encoderParams = new Proxy({}, {
            get(target, key, receiver) {
                return self.context.manipulator[`${self.encoderParamsName}.${key.toString()}`];
            },

            set(target, key, value, receiver) {
                self.context.manipulator[`${self.encoderParamsName}.${key.toString()}`] = value;
                return true;
            }
        });

        this.x264params = new Proxy({}, {
            get(target, key, receiver) {
                let parmsStr : string = self.encoderParams['x264-params'] || '';
                let pairs = parmsStr.split(':');
                let obj = {};
                pairs
                    .map(pair => pair.split('='))
                    .forEach(([k, v]) => {
                        let value : any = v;
                        if (value === '0')
                            value = false;
                        if (value === '1')
                            value = true;
                        
                        return obj[k] = value;
                    });

                return obj[key.toString()];
            },

            set(target, key, value, receiver) {
                let parmsStr : string = self.encoderParams['x264-params'] || '';
                let pairs = parmsStr.split(':');
                let obj = {};
                pairs
                    .map(pair => pair.split('='))
                    .forEach(([k, v]) => obj[k] = v);

                if (value === true)
                    value = '1';
                else if (value === false)
                    value = '0';

                obj[key.toString()] = value;

                self.encoderParams['x264-params'] = 
                    Object.keys(obj).map(key => `${key}=${obj[key]}`).join(':')
                ;

                return true;
            }
        });

    }

    async ngOnInit() {
        this.allCodecs = await this.sessionRef.session.getCodecs();
    }

    encoderParams : any;
    x264params : any;

    @ViewChild('codecSearchBox')
    codecSearchBox : ElementRef<HTMLElement>;

    codecSearchQuery : string = '';
    codecSelectorVisible = false;

    showCodecSelector() {
        this.codecSelectorVisible = true;
        setTimeout(() => this.codecSearchBox.nativeElement.focus());
    }

    selectCodec(codec : FFMPEGCodec, encoder : string) {
        if (!this.property)
            return;
        
        this.codecSelectorVisible = false;
        this.context.manipulator[this.codecParamName] = encoder;
        this.context.manipulator[this.encoderParamsName] = {};
    }

    get codecParamName() {
        if (this.codecType === 'video') {
            return 'data.params.c:v';
        } else if (this.codecType === 'audio') {
            return 'data.params.c:a';
        }
    }

    get encoderParamsName() {
        if (this.codecType === 'video') {
            return 'data.c:v:params';
        } else if (this.codecType === 'audio') {
            return 'data.c:a:params';
        }
    }

    get selectedCodec() {
        let encoder = this.encoder;
        let codec = this.codecs.find(x => x.id === encoder 
                || (x.encoders && x.encoders.includes(encoder)));

        if (codec)
            return codec;
    }

    get encoder() {
        return this.context.manipulator[this.codecParamName];
    }

    get session() {
        return this.sessionRef.session;
    }

    private _codecs = null;
    allCodecs : FFMPEGCodec[] = null;

    get codecType() {
        if (this.property.path === 'video')
            return 'video';
        else if (this.property.path === 'audio')
            return 'audio';
        
        return this.property.type === 'livefire:ffcodec_audio' ? 'audio' : 'video';
    }

    get codecs(): FFMPEGCodec[] {
        if (!this._codecs)
            this._codecs = this.allCodecs.filter(x => x && x.type === this.codecType);
        return this._codecs;
    }
 
    get multipleValues() : boolean {
        return this.context.manipulator[this.context.property.path + '?'] === MULTIPLE_VALUES;
    }
    
    get currentValue() : number[] {
        let str : string = this.context.manipulator[this.context.property.path];

        if (!str)
            return [];
        
        return str.split(' ').map(x => parseFloat(x));
    }

    get property() {
        return this.context.property;
    }
    
    get currentType() {
        return this.context.manipulator[this.matrixTypePath] || 'square';
    }

    get rawMatrix() {
        return this.context.manipulator[this.context.property.path];
    }

    set rawMatrix(value) {
        this.context.manipulator[this.context.property.path] = value;
    }

    get rawMode() {
        return this.context.manipulator[this.matrixTypePath] || 'square';
    }

    set rawMode(value) {
        this.context.manipulator[this.matrixTypePath] = value;
    }

    get currentTypeLabel() {
        let type = this.currentType;
        let values = this.currentValue;

        if (type === 'square') {
            let square = Math.round(Math.sqrt(values.length));
            if (values.length === 0)
                square = this.squareSizes[0];
                
            return `${square}x${square}`;
        }

        if (type === 'row')
            return `Row (${values.length} wide)`;
        else if (type === 'column')
            return `Column (${values.length} tall)`;

        return 'Unknown';
    }

    startEdit() {
        this.editing = true;
        this.rowSize = this.currentValue.length || 1;
        this.values = this.currentValue;

        if (this.currentType === 'square') {
            if (this.values.length === 0) {
                this.selectedType = `${this.squareSizes[0]}`;
            } else {
                this.selectedType = `${Math.round(Math.sqrt(this.values.length))}`;
            }
        } else {
            this.selectedType = this.currentType;
        }
    }

    saveEdit() {
        let type = this.selectedType;
        if (!['row', 'column'].includes(this.selectedType))
            type = 'square';

        this.context.manipulator[this.matrixTypePath] = type;

        let values = this.values.slice(0, this.count);

        while (values.length < this.count)
            values.push(0);
        
        this.context.manipulator[this.context.property.path] = values.join(' ');
        this.editing = false;
    }

    discardEdit() {
        this.editing = false;
    }

    rowSize = 1;
    selectedType : string;
    values : number[] = [];

    get matrixTypePath() {
        return this.options.matrixTypePath;
    }

    get width() {
        if (['row', 'column'].includes(this.selectedType))
            return 4;

        return parseInt(this.selectedType);
    }

    editing = false;

    numericRange(min, max, step = 1) {
        let array = [];

        for (let i = min; i < max; i += step)
            array.push(i);

        return array;
    }

    get count() {
        if (['row', 'column'].includes(this.selectedType))
            return this.rowSize;

        return Math.pow(parseInt(this.selectedType), 2);
    }

    get options(): FFMatrixPropertyOptions {
        return this.context.property.typeOptions || {};
    }

    get squareSizes() {
        return this.options.squareSizes;
    }

    get allowSquare() {
        return this.options.squareSizes && this.options.squareSizes.length > 0;
    }

    get allowRow() {
        return this.options.maxRowSize && this.options.maxRowSize > 0;
    }

    get allowColumn() {
        return this.options.maxColumnSize && this.options.maxColumnSize > 0;
    }

    get maxRowSize() {
        return this.options.maxRowSize;
    }

    get maxColumnSize() {
        return this.options.maxColumnSize;
    }
}