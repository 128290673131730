<div class="property">
    <label>{{property.label}}</label>
</div>

<ng-container *ngIf="!editing">
    <ng-container *ngIf="multipleValues">
        <em>Multiple values</em>
    </ng-container>
    <ng-container *ngIf="!multipleValues">

        <div style="display: flex; flex-direction: row; align-items: center;">
            <div style="flex-grow: 1;">
                <div class="property">
                    <label>Type</label>
                    {{currentTypeLabel}}
                </div>
                <div class="property">
                    <label>Elements</label>
                    <ng-container *ngIf="currentValue && currentValue.length > 0">
                        {{currentValue.length}} values
                    </ng-container>
                    <ng-container *ngIf="!currentValue || currentValue.length === 0">
                        Not set
                    </ng-container>
                </div>
            </div>
            <div style="text-align: right;">
                <button mat-raised-button color="primary" (click)="startEdit()">
                    <mat-icon>edit</mat-icon>
                    Edit
                </button>
            </div>
        </div>
        <br/>
        <br/>
    </ng-container>
</ng-container>
<ng-container *ngIf="editing">
    <ng-container *ngIf="rawEdit">
        <div>
            <mat-form-field appearance="outline" floatLabel="always"  style="width: 100%;">
                <mat-label>Mode</mat-label>
                <mat-select [(ngModel)]="rawMode">
                    <mat-option value="square">Square</mat-option>
                    <mat-option value="row">Row</mat-option>
                    <mat-option value="column">Column</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" floatLabel="always" style="width: 100%;">
                <mat-label>Matrix</mat-label>
                <textarea matInput [(ngModel)]="rawMatrix" style="min-height: 7em;"></textarea>
            </mat-form-field>
        </div>

        <div style="text-align: right;">
            <button mat-raised-button color="primary" (click)="editing = false; rawEdit = false;">
                Done
            </button>
            <br/>
            <br/>
        </div>
    </ng-container>
    <ng-container *ngIf="!rawEdit">

        <div style="display: flex; align-items: baseline;">
            <mat-form-field appearance="outline" floatLabel="always" style="width: 9em;">
                <mat-label>Type</mat-label>
                <mat-select placeholder="3x3" [(ngModel)]="selectedType">
                    <mat-optgroup label="Square" *ngIf="allowSquare">
                        <mat-option *ngFor="let size of squareSizes" value="{{size}}">
                            {{size}}x{{size}}
                        </mat-option>
                    </mat-optgroup>
                    
                    <mat-option *ngIf="allowRow" value="row">Row</mat-option>
                    <mat-option *ngIf="allowColumn" value="column">Column</mat-option>
                </mat-select>

            </mat-form-field>
            <ng-container *ngIf="['row', 'column'].includes(selectedType)">
                &nbsp;
                <mat-form-field appearance="outline" floatLabel="always" style="width: 6em;">
                    <mat-label>Size</mat-label>
                    <input type="number" matInput [(ngModel)]="rowSize" />
                </mat-form-field>
            </ng-container>
            <div class="spacer"></div>
            <button mat-button (click)="rawEdit = true">
                <mat-icon>code</mat-icon>
            </button>
        </div>
        <div *ngIf="count > 0" class="matrix" [attr.data-width]="width">
            <ng-container *ngFor="let i of numericRange(0, count)">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{i}}</mat-label>
                    <input type="number" matInput [(ngModel)]="values[i]" placeholder="0" />
                </mat-form-field>
            </ng-container>
        </div>

        <div style="text-align: right;">
            <button mat-button (click)="discardEdit()">
                <mat-icon>close</mat-icon>
                Discard
            </button>
            <button mat-raised-button color="primary" (click)="saveEdit()">
                <mat-icon>save</mat-icon>
                Save
            </button>
            <br/>
            <br/>
        </div>
    </ng-container>
</ng-container>