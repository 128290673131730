<mat-menu #serverMenu="matMenu">
    <ng-template matMenuContent let-server="server">
        <a mat-menu-item (click)="editServer(server)">
            <mat-icon>settings</mat-icon>
            Settings
        </a>
        <a mat-menu-item (click)="deleteServer(server)">
            <mat-icon>delete</mat-icon>
            Delete
        </a>
    </ng-template>
</mat-menu>
  
<div class="toolbar">
    <h1>
        <img src="/assets/splicepoint-logo.svg" style="height: 2em; vertical-align: middle; margin-right: -0.4em;" />
         servers
    </h1>

    <button mat-raised-button color="primary" (click)="showAddServer()">
        <mat-icon>add</mat-icon>
        Add
    </button>

</div>

<div class="loading" *ngIf="!servers">
    <mat-spinner></mat-spinner>
</div>

<div class="server-list" *ngIf="servers">
    <div class="server" *ngFor="let server of servers">
        <a routerLink="/servers/{{server.id}}">
            {{server.name}}
            <ul class="facts">
                <li *ngIf="server.default">
                    Default
                </li>
                <li>{{server.url}}</li>
            </ul>
        </a>

        <div class="spacer"></div>

        <a  mat-icon-button 
            [matMenuTriggerFor]="serverMenu" 
            [matMenuTriggerData]="{server: server}"
            >
            <mat-icon>more_vert</mat-icon>
        </a>
    </div>
</div>

<div class="empty-state" *ngIf="servers && servers.length === 0">
    <div class="island">
        <h1>No servers added yet.</h1>
        <p>
            Click the  
            
            &nbsp;
            <button mat-raised-button color="primary" (click)="showAddServer()">
                <mat-icon>add</mat-icon>
                Add
            </button>
            &nbsp;
            
            button to add a new Playout server.
        </p>
    </div>
</div>