import { Component, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { BackendService, BackendSession, FFMPEGCodec } from '../backend.service';
import { MatDialog } from '@angular/material/dialog';
import { InputEditorComponent } from '../input-editor/input-editor.component';
import { OutputEditorComponent } from '../output-editor/output-editor.component';
import { DiazoGraph, DiazoContext, DiazoPropertySet, DiazoNodeSet, DiazoCustomPropertyType, DiazoNode, DiazoPropertyOptionGroup } from 'diazo';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ShellService } from '../shell.component';
import { FFMatrixPropertyEditorComponent } from '../ff-matrix-property-editor/ff-matrix-property-editor.component';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { PipelineEditorComponent } from '../pipeline-editor/pipeline-editor.component';
import { FFCodecEditorComponent } from '../ff-codec-editor/ff-codec-editor.component';
import { BackendSessionRef } from '../backend-session-ref';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NoteNodeComponent } from '../note-node.component';
import { PIPELINE_TYPES } from '@astronautlabs/playout-server-diazo';
import { Pipeline } from 'src/config';

@Component({
    templateUrl: './pipeline-view.component.html',
    styleUrls: ['./pipeline-view.component.scss']
})
export class PipelineViewComponent { 
    constructor(
        private backendService : BackendService,
        private matDialog : MatDialog,
        private route : ActivatedRoute,
        private router : Router,
        private location : Location,
        private shell : ShellService,
        private matSnackBar : MatSnackBar
    ) {
        console.log(`Value types:`);
        console.dir(this.valueTypes.map(x => new x()));
    }

    backend : BackendSession;

    get providers() {
        return [
            { provide: BackendSessionRef, useValue: new BackendSessionRef(() => this.backend) }
        ];
    }

    get footerVisible() {
        if (this.selectedPipeline && this.selectedPipeline.errors && this.selectedPipeline.errors.length > 0) {
            return true;
        }

        return false;
    }

    async removePipeline() {
        if (!this.selectedPipeline)
            return;

        if (!confirm(`Are you sure you wish to remove pipeline '${this.selectedPipeline.name}'?`))
            return;

        try {
            await this.backend.removePipelineById(this.selectedPipelineID);
        } catch (e) {
            alert(`Failed to remove pipeline: ${e.message}`);
            return;
        }

        this.selectedPipelineID = null;
        this.fetch();
    }

    dynamicOptionSources : Record<string,Record<string,DiazoPropertyOptionGroup[]>> = {};

    get nodeTypeMap() : Record<string,any> {
        return {
            'note': NoteNodeComponent
        };
    }

    routerGraph : DiazoGraph = {
        nodes: [],
        edges: []
    }

    valueTypes = PIPELINE_TYPES;

    breakdown : any;

    defaultGraph : DiazoGraph = {
        edges: [],
        nodes: []
    }

    @ViewChild('tabs')
    tabs : MatTabGroup;

    @ViewChild('errorTab')
    errorTab : MatTab;
    
    selectedTabIndex : number;

    get activeTab() {
        if (!this.tabs)
            return undefined;
        
        return this.tabs._tabs.toArray()[this.selectedTabIndex];
    }

    switchToError(error : any) {
        let index = this.tabs._tabs.toArray().findIndex(x => x === this.errorTab);
        if (index >= 0)
            this.selectedTabIndex = index;
        else
            console.warn(`Failed to find errors tab`);
    }

    graphDirty = false;

    editorOptions = {
        theme: 'vs-dark', 
        language: 'json',
        automaticLayout: true
    };

    get selectedPipelineJSON() {
        if (!this.selectedPipeline)
            return null;
        
        return JSON.stringify(this.selectedPipeline, undefined, 2);
    }

    set selectedPipelineJSON(value) {
        if (!this.selectedPipeline)
            return;
        
        Object.assign(this.selectedPipeline, JSON.parse(value));
    }

    updatePipeline(pipeline : any) {
        let index = this.pipelines.findIndex(x => x.id === pipeline.id);
        if (index >= 0) {
            this.pipelines[index] = pipeline;
        } else {
            this.pipelines.push(pipeline);
        }
    }

    get runnable() {
        return this.selectedPipeline && ['continuous', 'on-demand'].includes(this.selectedPipeline.executionType);
    }

    async runPipeline() {

    }

    async enablePipeline() {
        if (!this.selectedPipeline)
            return;
        
        try {
            let updatedPipeline = await this.backend.enablePipeline(this.selectedPipeline.id);
            this.updatePipeline(updatedPipeline);
        } catch (e) {

            alert(`Failed to enable pipeline: [${e.error}] ${e.message}`);

            return;
        }
        
        this.fetch();
    }

    async disablePipeline() {
        if (!this.selectedPipeline)
            return;
        
        this.updatePipeline(await this.backend.disablePipeline(this.selectedPipeline.id));
        this.fetch();
    }

    async editPipeline() {
        if (!this.selectedPipelineID)
            return;
        
        let ref = this.matDialog.open(PipelineEditorComponent, {
            data: {
                serverID: this.backend.id,
                id: this.selectedPipelineID
            }
        });

        await ref.afterClosed().toPromise();

        this.loading = true;
        this.fetch();
        this.loading = false;

    }

    loading = false;

    get pipelineEnabled() {
        if (!this.selectedPipeline)
            return false;

        return this.selectedPipeline.enabled;
    }

    set pipelineEnabled(value) {
        if (value == this.selectedPipeline.enabled)
            return;
        
        if (value) {
            this.enablePipeline();
        } else {
            this.disablePipeline();
        }
    }

    async saveGraph() {
        if (!this.selectedPipeline) {
            return;
        }

        if (!this.graphDirty) {
            this.matSnackBar.open("Pipeline already saved.", undefined, {
                duration: 3000
            });
            return;
        }

        await this.backend.updatePipeline(this.selectedPipeline, false);

        setTimeout(() => this.graphDirty = false);
        
        this.matSnackBar.open("Pipeline saved.", undefined, {
            duration: 3000
        });
    }

    onGraphChanged($event : DiazoGraph) {
        if (!this.selectedPipeline)
            return;
        
        if (!$event || !this.selectedPipeline)
            return;

        setTimeout(() => this.graphDirty = true);
        this.selectedPipeline.graph = $event;
    }

    async showAddPipeline() {
        let ref = this.matDialog.open(PipelineEditorComponent, {
            data: {
                serverID: this.backend.id,
                id: 'new'
            }
        });

        let cmp = ref.componentInstance;

        await ref.afterClosed().toPromise();
        await this.fetch();

        if (cmp.savedPipeline)
            this.selectedPipelineID = cmp.savedPipeline.id;
    }

    selectedStageIndex = 0;

    private _selectedPipelineID : string = null;

    get selectedPipelineID() : string {
        return this._selectedPipelineID;
    }

    pipelineSession;

    set selectedPipelineID(value) {
        this._selectedPipelineID = value;
        this.location.replaceState(`/servers/${this.backend.id}/pipelines/${value || 'list'}`);
        this.fetchPipeline();
    }

    async fetchPipeline() {
        console.log(`Fetching pipeline ${this.selectedPipelineID}`);
        let id = this.selectedPipelineID;
        let pipeline = await this.backend.getPipeline(id);
        
        await this.updateNodes(pipeline);

        this.selectedPipeline = pipeline;
        console.log(`Fetched pipeline ${this.selectedPipelineID}`);
        console.dir(this.selectedPipeline.graph);
        //await this.fetchSession();
    }

    async fetchSession() {
        let pipeline = this.selectedPipeline;

        this.pipelineSession = null;
        if (!pipeline)
            return;

        console.warn(`Fetching session for pipeline ${this.selectedPipelineID}...`);
        this.pipelineSession = await this.backend.getPipelineSession(this.selectedPipelineID);

        if (this.pipelineSession) {
            console.info(` -- Session:`);
            console.dir(this.pipelineSession);
        } else {
            console.info(` -- No session found for pipeline ${this.selectedPipelineID}`);
        }
    }

    selectedPipeline : Pipeline;
    

    availableNodes : DiazoNodeSet[] = [];
    customPropertyTypes : DiazoCustomPropertyType[] = [];

    saveBackDebugGraph(graph : DiazoGraph) {
        if (!graph)
            return;
        
        this.selectedPipeline.compilation.debug.stages[this.selectedStageIndex].graph = graph;
    }

    async updateNodes(selectedPipeline? : Pipeline) {
        let nodes : DiazoNodeSet[] = [];
        
        if (selectedPipeline) {
            if (selectedPipeline.executionType === 'reusable') {

                let slotNodes : DiazoNode[] = [];

                if (selectedPipeline.slots) {
                    for (let slot of selectedPipeline.slots) {
                        slotNodes.push({
                            label: `(${slot.type === 'input' ? 'Input' : 'Output'}) ${slot.label}`,
                            data: {
                                type: slot.type,
                                unit: 'slot',
                                slotId: slot.id
                            },
                            slots: [
                                {
                                    id: slot.id,
                                    type: slot.type === 'input' ? 'output' : 'input',
                                    label: slot.label,
                                    value: slot.value
                                }
                            ]
                        })
                    }
                }

                nodes.push({
                    label: 'I/O',
                    nodes: slotNodes
                })
            }
        }

        // Add local set

        if (this.pipelines) {
            let localSet : DiazoNodeSet = {
                id: 'local',
                tags: [],
                label: 'Local',
                nodes: []
            }

            for (let pipeline of this.pipelines.filter(x => x.executionType === 'reusable')) {

                // Do not allow recursion.
                if (pipeline.id === this.selectedPipelineID)
                    continue;

                let pipelineType = 'filter';
                let takesInput = pipeline.slots.some(x => x.type === 'input');
                let givesOutput = pipeline.slots.some(x => x.type === 'output');

                if (takesInput && givesOutput) {
                    pipelineType = 'filter';
                } else if (takesInput) {
                    pipelineType = 'output';
                } else {
                    pipelineType = 'input';
                }

                localSet.nodes.push({
                    data: {
                        type: pipelineType,
                        unit: `pipeline`,
                        pipelineId: pipeline.id
                    },
                    slots: pipeline.slots,
                    label: pipeline.name
                });
            }

            if (localSet.nodes.length > 0)
                nodes = nodes.concat(localSet);
        }

        let serverNodes = await this.backend.getPipelineNodes()
        nodes = nodes.concat(serverNodes);

        console.log(`Available nodes updated:`);
        console.dir(nodes);
        this.availableNodes = nodes;

        this.customPropertyTypes = [
            { namespace: 'livefire', id: 'ffmatrix', component: FFMatrixPropertyEditorComponent },
            { namespace: 'livefire', id: 'ffcodec', component: FFCodecEditorComponent }
        ]

        this.updateSources();
    }

    codecs : FFMPEGCodec[];

    async updateSources() {    
        if (!this.codecs)
            this.codecs = await this.backend.getCodecs()
        
        let codecs : any[] = this.codecs;

        this.dynamicOptionSources = {
            audioCodecs: {
                default: 
                    codecs.filter(x => x.type === 'audio')
                          .map(x => ({
                              label: x.name,
                              options: (x.encoders || [ x.id ]).map(x => ({ value: x, label: x }))
                            }))
            },
            videoCodecs: {
                default: 
                    codecs.filter(x => x.type === 'video')
                          .map(x => ({ 
                              label: `[${x.id}] ${x.name}`,
                              options: (x.encoders || [ x.id ]).map(x => ({ value: x, label: `Encode with ${x}` }))
                            }))
            },
            videoCodecPresets: {
                libx264: [
                    {
                        label: 'libx264 Presets',
                        options: [ 
                            { value: 'fast', label: 'Fast' },
                            { value: 'veryfast', label: 'Very Fast' }
                        ]
                    }
                ]
            }
        };
    }

    getSessionState(session : any) {
        if (!session)
            return 'not-started';
        
        if (session.ended && session.error) {
            return 'error';
        }

        if (session.ended)
            return 'success';

        return 'running';
    }

    entries : any[];
    fixedEntries : any[];
    graphContext : DiazoContext;

    refreshInterval;

    subsink = new SubSink();

    async ngOnInit() {
        this.shell.title = `Pipelines`;

        this.route.paramMap.subscribe(params => {
            let id = params.get('id');
            let serverID = params.get('server');

            this.backend = this.backendService.getSession(serverID);
            this.fetch();

            if (id === 'list')
                this.selectedPipelineID = null;
            else
                this.selectedPipelineID = id;

        });

        //this.refreshInterval = setTimeout(() => this.fetch(), 1000);

        this.subsink.add(this.backend.notifications.subscribe(notif => {
            if (notif.type === 'pipeline:updated' || notif.type === 'pipeline:added') {
                let pipeline : any = notif.data.pipeline;
                let index = this.pipelines.findIndex(x => x.id === pipeline.id);

                console.warn(`Received update for pipeline ${pipeline.id}:`);
                console.dir(pipeline);

                if (pipeline.running) {
                    let graph : DiazoGraph = pipeline.graph;

                    for (let edge of graph.edges) {
                        edge.active = true;
                    }
                }

                if (index >= 0) {
                    this.pipelines[index] = pipeline;
                } else {
                    this.pipelines.push(pipeline);
                }
            } else if (notif.type === 'pipeline:removed') {
                let pipeline : any = notif.data.pipeline;
                let index = this.pipelines.findIndex(x => x.id === pipeline.id);

                if (index >= 0)
                    this.pipelines.splice(index, 1);

            } else if (notif.type === 'session:added' || notif.type === 'session:updated' || notif.type === 'session:ended') {
                let session = notif.data.session;

                if (this.selectedPipeline) {
                    if (session.description.id === this.selectedPipeline.id) {
                        this.pipelineSession = session;
                    }
                }
            }
        }));
    }

    async ngOnDestroy() {
        this.subsink.unsubscribe();
        clearInterval(this.refreshInterval);
    }

    universalPropertySets : DiazoPropertySet[] = [
        {
            id: 'general',
            label: 'General',
            description: 'Common properties',
            properties: [
                {
                    label: 'Label',
                    description: '',
                    path: '$.label',
                    allowAnnotation: false,
                    type: 'text'
                },
                {
                    label: 'Style',
                    path: 'style',
                    allowAnnotation: false,
                    type: 'select',
                    options: [
                        { value: 'standard', label: 'Standard' },
                        { value: 'compact', label: 'Compact' }
                    ]
                },
                {
                    label: 'Unit',
                    description: '',
                    readonly: true,
                    allowAnnotation: false,
                    path: 'data.unit',
                    type: 'text'
                },
                {
                    label: 'Position',
                    description: '',
                    readonly: true,
                    allowAnnotation: false,
                    type: 'position'
                },
                {
                    label: 'ID',
                    description: '',
                    readonly: true,
                    allowAnnotation: false,
                    path: 'id',
                    type: 'text'
                },
            ]
        },
        {
            id: 'notes',
            label: 'Documentation',
            properties: [
                {
                    type: 'markdown',
                    path: 'data.docs.notes',
                    label: 'Notes',
                    allowAnnotation: false,
                    slottable: false
                }
            ]
        }
    ];

    pipelines : any[] = [];
    
    async fetch() {
        this.entries = [];
        this.fixedEntries = [];

        await this.backend.serviceInfoReady();

        this.pipelines = await this.backend.getPipelines();

        if (await this.backend.hasCapability('router')) {
            this.entries = await this.backend.getRouterEntries();
            this.fixedEntries = await this.backend.getFixedRouterEntries();
        }
        
        await this.updateNodes();
    }

    newInput() {
        let ref = this.matDialog.open(InputEditorComponent, { data: { id: 'new' }});
        ref.afterClosed().subscribe(() => this.fetch());
    }

    getLabelForFixedEntry(entry) {
        if (entry.type === 'input') {
            
            if (entry.descriptor.type === 'rtmp') {
                return entry.descriptor.edge;
            } else if (entry.descriptor.type === 'udp') {
                return entry.descriptor.port;
            } else if (entry.descriptor.type === 'http') {
                return entry.descriptor.url;
            } else if (entry.descriptor.type === 'file') {
                return entry.descriptor.filename;
            }

        } else if (entry.type === 'output') {
            return entry.descriptor.endpoint;
        }

        return '?/?'
    }

    newOutput() {
        let ref = this.matDialog.open(OutputEditorComponent, { 
            data: { id: 'new' },
        });

        ref.afterClosed().subscribe(() => this.fetch());
    }

    editInput(id : string) {
        let ref = this.matDialog.open(InputEditorComponent, { data: { id }});
        ref.afterClosed().subscribe(() => this.fetch());
    }

    editOutput(id : string) {
        let ref = this.matDialog.open(OutputEditorComponent, { data: { id }});
        ref.afterClosed().subscribe(() => this.fetch());
    }

    editFixedEntry(fixedEntry : any) {
        if (fixedEntry.type === 'input')
            this.editInput(fixedEntry.id);
        else
            this.editOutput(fixedEntry.id);
    }
}