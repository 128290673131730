
<ng-container *ngIf="cluster">
    <div class="toolbar">
        <h1>Cluster</h1>
        <button mat-raised-button color="primary" (click)="showRegister()">
            <mat-icon>add</mat-icon>
            Add Node
        </button>
        
    </div>
</ng-container>
<ng-container *ngIf="!cluster">
    <mat-spinner></mat-spinner>
</ng-container>