<ng-container *ngIf="web">
  
  <nav id="main-nav">
    <a class="logo" routerLink="/web">
      <img src="/assets/logo.svg" style="height: 2em; position: relative; top: -0.15em;" />
    </a>

    <a mat-button routerLink="/web/pricing">Pricing</a>
    <a mat-button routerLink="/web/features">Features</a>

    <div class="spacer"></div>
  </nav>
</ng-container>
<ng-container *ngIf="!web">
  <mat-menu #overflowMenu="matMenu">
    <a mat-menu-item routerLink="/sign/in">
        <mat-icon>check_circle</mat-icon>
        Sign In
    </a>
    <a mat-menu-item routerLink="/settings">
        <mat-icon>settings</mat-icon>
        Settings
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/web">
        <mat-icon>public</mat-icon>
        Website
    </a>
    <a mat-menu-item (click)="showAbout()">
        <mat-icon>whatshot</mat-icon>
        About
    </a>

  </mat-menu>

  <nav id="main-nav">
    <a [style.color]="headerTheme.logoColor" class="logo" routerLink="/">
      <img src="/assets/logo.svg" style="height: 2em; position: relative; top: -0.15em;" />
    </a>

    <div class="breadcrumbs">
      <ng-container *ngIf="currentSession">
        <a mat-icon-button routerLink="/servers">
          <mat-icon>dns</mat-icon>
        </a>
        <a mat-button routerLink="/servers/{{currentBackendID}}">
          {{currentSession.backend.name}}
        </a>
      </ng-container>
    </div>
    <div class="spacer"></div>

    <a mat-icon-button [matMenuTriggerFor]="overflowMenu">
      <mat-icon>more_vert</mat-icon>
    </a>
  </nav>

  <nav id="sub" *ngIf="currentSession?.status">
    <img src="/assets/splicepoint-logo.svg" 
      class="waypoint-logo" />

    <a mat-button routerLink="/servers/{{currentBackendID}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
      <mat-icon>home</mat-icon>
      Dashboard
    </a>
    <!-- <a mat-button routerLink="/servers/{{currentBackendID}}/apps" routerLinkActive="active">
      <mat-icon>apps</mat-icon>
      Apps
    </a> -->
    <a mat-button routerLink="/servers/{{currentBackendID}}/pipelines" routerLinkActive="active">
      <mat-icon>movie</mat-icon>
      Pipelines
    </a>
    <a mat-button routerLink="/servers/{{currentBackendID}}/workflows" routerLinkActive="active">
      <mat-icon>assignment</mat-icon>
      Workflows
    </a>
    <a mat-button routerLink="/servers/{{currentBackendID}}/monitor" routerLinkActive="active">
      <mat-icon>tv</mat-icon>
      Monitor
    </a>
    <a mat-button routerLink="/servers/{{currentBackendID}}/cluster" routerLinkActive="active">
      <mat-icon>cloud</mat-icon>
      Cluster
    </a>
    <a mat-button routerLink="/servers/{{currentBackendID}}/settings" routerLinkActive="active">
      <mat-icon>settings</mat-icon>
      Settings
    </a>
  </nav>
</ng-container>

<router-outlet></router-outlet>