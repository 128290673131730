import { Injectable } from "@angular/core";
import { Pipeline } from 'src/config';
import * as uuid from 'uuid/v4';

@Injectable()
export class PipelinesService {
    _programs : Pipeline[] = [];
    async all() : Promise<Pipeline[]> {
        return this._programs;
    }

    async get(id : string): Promise<Pipeline> {
        return this._programs.find(x => x.id === id);
    }

    async save(program : Pipeline) {
        if (program.id) {
            this._programs = this._programs.filter(x => x.id !== program.id);
            this._programs.unshift(program);
        } else {
            program.id = uuid();
            this._programs.unshift(program);
        }
    }

    async delete(program : Pipeline) {
        if (!program.id)
            return;

        this._programs = this._programs.filter(x => x.id !== program.id);
    }
}