import { Component, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendService, BackendSession } from '../backend.service';

@Component({
    templateUrl: './output-editor.component.html',
    styleUrls: ['./output-editor.component.scss']
})
export class OutputEditorComponent {
    constructor(
        private backendService : BackendService,

        @Optional()
        private matDialogRef : MatDialogRef<OutputEditorComponent>,

        @Optional() @Inject(MAT_DIALOG_DATA)
        private matDialogData : any
    ) {

    }

    backend : BackendSession;
    serverID : string;

    ngOnInit() {
        if (this.matDialogData) {
            this.id = this.matDialogData.id;
            this.serverID = this.matDialogData.serverID;
            this.fetch()
        } else {
            // TODO: routing
        }
    }

    id : string = null;
    output : any = {};
    entry : any = null;
    
    async fetch() {
        this.backend = this.backendService.getSession(this.serverID);
        
        if (this.id === 'new') {
            this.entry = {
                type: 'output',
                path: '',
                descriptor: {

                }
            }
            this.output = this.entry.descriptor;
        } else {
            this.entry = await this.backend.getFixedRouterEntry(this.id);
            this.output = this.entry.descriptor;
        }
    }

    cancel() {
        this.matDialogRef.close();
    }

    async save() {
        if (this.entry.id) {
            this.entry = await this.backend.updateFixedRouterEntry(this.entry);
        } else {
            this.entry = await this.backend.createFixedRouterEntry(this.entry);
        }

        this.matDialogRef.close();
    }

    get valid() {
        return false;
    }
}