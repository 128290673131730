import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './settings/settings.component';
import { MonitorComponent } from './monitor/monitor.component';
import { PipelineViewComponent } from './pipeline-view/pipeline-view.component';
import { PipelineEditorComponent } from './pipeline-editor/pipeline-editor.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServersListComponent } from './servers-list/servers-list.component';
import { ServerHomeComponent } from './server-home/server-home.component';
import { ClusterViewComponent } from './cluster-view/cluster-view.component';
import { PreviewComponent } from './preview/preview.component';
import { WorkflowViewComponent } from './workflow-view/workflow-view.component';
import { WorkflowEditorComponent } from './workflow-editor/workflow-editor.component';
import { AboutComponent } from './about/about.component';
import { ClientSettingsComponent } from './client-settings/client-settings.component';
import { FeaturesComponent, PricingComponent, FaqComponent } from '@astronautlabs/chassis';
import { WebHomeComponent } from './web-home/web-home.component';

const routes: Routes = [
  { path: '',                                      component: HomeComponent },
  { path: 'about',                                 component: AboutComponent },
  { path: 'servers',                               component: ServersListComponent },
  { path: 'servers/:server',                       component: ServerHomeComponent },
  { path: 'settings',                              component: ClientSettingsComponent },

  { path: 'web',                                   component: WebHomeComponent },
  { path: 'web/features',                          component: FeaturesComponent },
  { path: 'web/pricing',                           component: PricingComponent },
  { path: 'web/faq',                               component: FaqComponent },

  { path: 'servers/:server/pipelines',                 component: PipelineViewComponent },
  { path: 'servers/:server/pipelines/:id',             component: PipelineViewComponent },
  { path: 'servers/:server/pipelines/new',             redirectTo: 'pipelines/new/edit' },
  { path: 'servers/:server/pipelines/:id/edit',        component: PipelineEditorComponent },

  { path: 'servers/:server/workflows',             component: WorkflowViewComponent },
  { path: 'servers/:server/workflows/:id',         component: WorkflowViewComponent },
  { path: 'servers/:server/workflows/new',         redirectTo: 'workflows/new/edit' },
  { path: 'servers/:server/workflows/:id/edit',    component: WorkflowEditorComponent },

  { path: 'servers/:server/settings',              component: SettingsComponent },
  { path: 'servers/:server/monitor',               component: MonitorComponent },
  { path: 'servers/:server/cluster',               component: ClusterViewComponent },
  { path: 'servers/:server/preview',               component: PreviewComponent },
  { path: 'servers/:server/preview/:input',        component: PreviewComponent },
  { path: 'servers/:server/preview/:input/:type',  component: PreviewComponent },
  { path: '**',                                    component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
