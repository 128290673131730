import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class ShellService {
    constructor(
        private titleService : Title
    ) {
        
    }

    set title(value : string) {
        if (!value)
            this.titleService.setTitle(`livefire`);
        else
            this.titleService.setTitle(`${value} - livefire`);
    }

    get title() {
        return this.titleService.getTitle();
    }
}