<div class="loading" *ngIf="!server || !session">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="server && session">

    <div class="empty-state" *ngIf="!session.status && !session.connected">
        <h1>
            <a routerLink="/servers">Servers</a>
            &raquo;
            {{server.name}}
        </h1>
        <h2>is offline</h2>

        <div class="connecting">
            <div class="spin-container">
                <mat-spinner [strokeWidth]="0.75"></mat-spinner>
                <mat-spinner [strokeWidth]="0.25" class="bg"></mat-spinner>
            </div>
            <p>
                Attempting to establish connection...
            </p>

            <p>
                This server is offline and no previous status information is 
                available. LiveFire will continue to attempt to connect to this 
                server in the background. 
            </p>
        </div>
        
    </div>

    <ng-container *ngIf="session.status">
        <h1>
            <a routerLink="/servers">Servers</a>
            &raquo;
            {{server.name}}
        </h1>

        <ul class="facts">
            <li>{{server.url}}</li>
            <ng-container *ngIf="session.serviceInfo">
                <li>
                    <ng-container *ngIf="session.serviceInfo.productName">
                        <strong>{{session.serviceInfo.productName}}</strong>
                    </ng-container>

                    {{session.serviceInfo.service}}@{{session.serviceInfo.version}}
                </li>
            </ng-container>
            <li>
                <ng-container *ngIf="session.connected">
                    Up
                    <ng-container *ngIf="session.status">
                        {{hms(session.status.nodejs.uptime)}}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!session.connected">Down</ng-container>
            </li>
            <ng-container *ngIf="session.status">
                <li>
                    {{session.status.os.platform}}-{{session.status.os.arch}}-{{session.status.os.release}}
                </li>
                <li>
                    ffmpeg {{session.status.ffmpeg.version}}
                </li>
                <li>
                    {{session.status.cpu.model}}
                </li>
                <li>
                    {{session.status.cpu.num}} cores
                </li>
                <li> 
                    Node.js {{session.status.nodejs.version}}
                </li>
            </ng-container>
        </ul>

        <mat-tab-group>
            <mat-tab label="Status">
                <div class="widget-container">
                    <div class="widget">
                        <h1>CPU: {{session.status.cpu.load / 100 | percent}}</h1>
                        <div class="graph-container">
                            <ngx-charts-line-chart
                                [legend]="true"
                                [showXAxisLabel]="true"
                                [showYAxisLabel]="true"
                                [xAxis]="true"
                                [yAxis]="true"
                                xAxisLabel="Time"
                                yAxisLabel="% of CPU"
                                [yScaleMin]="0"
                                [yScaleMax]="100"
                                timeline="true"
                                [results]="cpuUsageData"
                                >
                                <ng-template #tooltipTemplate let-model="model">
                                    {{model.value / 100 | percent}} at {{model.name | date : 'short'}}
                                </ng-template>
                                <ng-template #seriesTooltipTemplate let-model="model">
                                    {{model[0].value / 100 | percent}}
                                </ng-template>
                            </ngx-charts-line-chart>
                            <div class="clear"></div>
                        </div>
                    </div>
                    <div class="widget">
                        <h1>
                            Memory: 
                            {{bytesToGB(session.status.mem.total - session.status.mem.free)}} /
                            {{bytesToGB(session.status.mem.total)}}
                        </h1>
                        <div class="graph-container">
                            <ngx-charts-line-chart
                                [legend]="true"
                                [showXAxisLabel]="true"
                                [showYAxisLabel]="true"
                                [xAxis]="true"
                                [yAxis]="true"
                                [yScaleMin]="0"
                                [yScaleMax]="100"
                                xAxisLabel="Time"
                                yAxisLabel="% of Memory"
                                timeline="true"
                                [results]="memUsageData"
                                >
                                <ng-template #tooltipTemplate let-model="model">
                                    {{model.value / 100 | percent}} used at {{model.name | date : 'short'}}
                                </ng-template>
                                <ng-template #seriesTooltipTemplate let-model="model">
                                    {{model[0].value / 100 | percent}} used
                                </ng-template>
                                
                            </ngx-charts-line-chart>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="FFMPEG" *ngIf="session?.status?.ffmpeg">

                <mat-accordion>
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>General</mat-expansion-panel-header>
                        <div class="property-set">
                            <div class="property">
                                <label>Version</label>
                                {{session.status.ffmpeg.version}}
                            </div>
                            
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel (afterExpand)="loadCodecs()">
                        <mat-expansion-panel-header>
                            Codecs
                        </mat-expansion-panel-header>
                        
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-icon matPrefix>search</mat-icon>

                            <mat-label>
                                Search
                            </mat-label>
            
                            <input type="text" matInput [(ngModel)]="codecSearchQuery" />
                            
                            <button mat-icon-button matSuffix *ngIf="codecSearchQuery" (click)="codecSearchQuery = ''">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>

                        <div style="min-height: 10em;" *ngIf="!codecs">
                            <mat-spinner></mat-spinner>
                        </div>
                        <table class="codecs" *ngIf="codecs">
                            <thead>
                                <tr>
                                    <th class="feature">Dec</th>
                                    <th class="feature">Enc</th>
                                    <th class="feature">IFO</th>
                                    <th class="feature">Lssy</th>
                                    <th class="feature">Lsls</th>
                                    <th class="type">Type</th>
                                    <th>Name</th>
                                    <th>ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let codec of codecs | omnisearch : codecSearchQuery">

                                    <ng-container *ngIf="!codec">
                                        <td colspan="9999">
                                            <div class="empty-state">
                                                <div class="island">
                                                    <h1>Nothing matches</h1>

                                                    <p>
                                                        Revise your search or clear the search box 
                                                        to see all codecs.
                                                    </p>

                                                    <div class="note">
                                                            Examples:
                                                            <ul>
                                                                <li><code>searchterm1 searchterm2 ...</code> -- Rank results on the given search terms</li>
                                                                <li><code>/regex.*/ig</code> -- Use a regular expression</li>
                                                                <li><code>$.foo.bar searchterm1 ...</code> -- Use a JSONPath expression to perform precise searches</li>
                                                            </ul>
                                                        </div>

                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="codec">
                                        <td 
                                            *ngFor="let feature of codecFeatures"
                                            class="feature" 
                                            [class.enabled]="codec[feature.id]"
                                            [attr.title]="codec[feature.id] ? feature.trueTooltip : feature.falseTooltip"
                                            >
                                            <mat-icon>
                                                {{codec[feature] ? 'check_circle' : 'cancel'}}
                                            </mat-icon>
                                        </td>

                                        <td class="type">{{codec.type}}</td>
                                        <td>{{codec.name}}</td>
                                        <td class="id"><code>{{codec.id}}</code></td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-tab>
            <mat-tab label="JSON">
                <pre>{{ session.status | json }}</pre>
            </mat-tab>
        </mat-tab-group>

        <h1>Service Info</h1>
        <pre>{{session.serviceInfo | json}}</pre>
    </ng-container>
</div>
