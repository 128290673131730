import { Component, Optional, Inject } from "@angular/core";
import { Workflow } from 'src/config';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService, BackendSession } from '../backend.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import * as uuid from 'uuid/v4';
import { DiazoTypeBase } from 'diazo';
import { PIPELINE_TYPES } from '@astronautlabs/playout-server-diazo';

@Component({
    templateUrl: './workflow-editor.component.html',
    styleUrls: ['./workflow-editor.component.scss']
})
export class WorkflowEditorComponent {
    constructor(
        private router : Router,
        private route : ActivatedRoute,
        private backendService : BackendService,
        private matDialog : MatDialog,

        @Optional()
        private matDialogRef : MatDialogRef<WorkflowEditorComponent>,

        @Optional() @Inject(MAT_DIALOG_DATA) 
        public matDialogData: any
    ) {
        this.valueTypes = PIPELINE_TYPES.map(x => new x());
    }

    valueTypes : DiazoTypeBase[] = [];
    backend : BackendSession;

    ngOnInit() {

        if (this.matDialogData) {
            this.loadTask(this.matDialogData.serverID, this.matDialogData.id);
        } else {
            this.route.paramMap.subscribe(async params => {
                let id = params.get('id');
                let serverID = params.get('server');
                this.loadTask(serverID, id);
            });
        }
    }

    private async loadTask(serverID : string, id : string) {
        this.backend = this.backendService.getSession(serverID);

        if (id === 'new') {
            this.workflow = {
                id: null,
                name: '',
                language: 'typescript',
                enabled: false,
                code: `
import { Workflow, Event, Actions } from '@astronautlabs/livefire';

@Workflow()
export class MyWorkflow {
    @Event("rest-request",{"method":"post","triggerName":"stuff"})
    onRestRequest() {
        console.log(\`Received REST request\`});
    }
}
                `,
                graph: {
                    nodes: [],
                    edges: []
                }
            }
        } else {
            this.workflow = await this.backend.getWorkflow(id);
            if (!this.workflow) {
                this.router.navigateByUrl('/tasks');
            }
        }
    }

    workflow : Workflow = <any>{
        name: 'This Thing'
    };

    session : any;

    reorderSlots(event) {
        moveItemInArray(this.workflow.slots, event.previousIndex, event.currentIndex);
    }

    addSlot() {
        if (!this.workflow.slots)
            this.workflow.slots = [];
        
        this.workflow.slots.push({
            id: uuid(),
            dynamic: false,
            label: '',
            type: 'input',
            value: {
                type: 'none',
                params: {}
            }
        });
    }

    async save(restart? : boolean) {

        if (!this.workflow.id || this.workflow.id === 'new') {
            // new 
            this.workflow = await this.backend.createWorkflow(this.workflow);
        } else {
            try {
                await this.backend.updateWorkflow(this.workflow, restart);
            } catch (e) {
                console.error(`Error while updating task:`);
                console.error(e);
                alert(`Failed to update task: ${e}`);
                return;
            }
        }

        //this.router.navigateByUrl(`/tasks/${this.program.id}`);

        this.savedWorkflow = this.workflow;
        if (this.matDialogRef)
            this.matDialogRef.close();
    }

    savedWorkflow : Workflow;

    get isPersisted() {
        return !!this.workflow.id;
    }
    get isDialog() {
        return !!this.matDialogRef;
    }

    discard() {
        if (this.matDialogRef)
            this.matDialogRef.close();
        else
            this.router.navigateByUrl(`/tasks/${this.workflow.id}`);
    }
}