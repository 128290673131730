<div class="toolbar">
    <h1>PREVIEW &raquo; </h1>
    <div>
        <div class="property" *ngIf="input">
            <label>Input</label>
            {{input}}
        </div>
        <div class="property" *ngIf="type">
            <label>Mode</label>
            {{type}}
        </div>
    </div>
</div>

<ng-container *ngIf="!input">
    Enter a source to preview:

    <div>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Input URL</mat-label>
            <input type="text" matInput />
        </mat-form-field>
    </div>
</ng-container>
<ng-container *ngIf="input">

    <ng-container *ngIf="!type">
        
        Do you want to load a Source Quality preview or a Low-Bandwidth preview?
        
        <ul>
            <li><strong>Source quality</strong> uses less CPU on the Playout server while 
                providing higher quality video to your browser, but you may experience 
                buffering if your network or device can't support the video.</li>
            <li>
                <strong>Low-Bandwidth</strong> previews use more CPU to produce on the 
                Playout server, but may deliver to your device with less buffering.
            </li>
        </ul>

        <p>We recommend using Source Quality unless you have trouble playing the media without 
            buffering.</p>

        <a mat-raised-button color="primary" routerLink="/servers/{{backend.id}}/preview/{{rawInput}}/source">Source Quality</a>
        &nbsp;
        <a mat-raised-button color="primary" routerLink="/servers/{{backend.id}}/preview/{{rawInput}}/low-bandwidth">Low Bandwidth</a>
    </ng-container>
    <ng-container *ngIf="type">

        <ng-container *ngIf="uiState !== 'playing' && uiState !== 'waiting'">

            <div style="text-transform: uppercase;">{{uiState}}</div>
            <div class="loading">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="uiState === 'playing' || uiState === 'waiting'">
            <div 
                *ngIf="uiState !== 'playing'" 
                style="text-transform: uppercase;"
                >{{uiState}}</div>
            <div class="video-container">
                <video 
                    #previewVideo
                    controls
                    ></video>
            </div>

        </ng-container>

    </ng-container>
</ng-container>