import { Component } from '@angular/core';
import { BackendService, Backend } from '../backend.service';
import { SubSink } from 'subsink';
import { MatDialog } from '@angular/material/dialog';
import { ServerEditorComponent } from '../server-editor/server-editor.component';

@Component({
    templateUrl: './servers-list.component.html',
    styleUrls: ['./servers-list.component.scss']
})
export class ServersListComponent {
    constructor(
        private backendService : BackendService,
        private matDialog : MatDialog
    ) {

    }

    servers : Backend[];
    sink = new SubSink();

    ngOnInit() {
        this.sink.add(
            this.backendService.backendsChanged
                .subscribe(backends => this.servers = backends)
        );
    }

    ngOnDestroy() {
        this.sink.unsubscribe();
    }

    showAddServer() {
        this.matDialog.open(ServerEditorComponent, {
            data: {
                id: 'new'
            }
        });
    }

    editServer(server : Backend) {
        this.matDialog.open(ServerEditorComponent, {
            data: {
                id: server.id
            }
        });
    }

    deleteServer(server : Backend) {
        if (confirm("Are you sure you want to remove this server?"))
            this.backendService.deleteBackend(server);

        this.servers = this.backendService.backends;
    }
}