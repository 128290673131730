<mat-menu #rtmpSessionOverflowMenu="matMenu">
    <button mat-menu-item>Disconnect</button>
    <button mat-menu-item>Item 2</button>
  </mat-menu>

<mat-tab-group>
    <mat-tab label="RTMP">
        <ng-container *ngIf="!rtmpSessions">
            <mat-spinner></mat-spinner>
        </ng-container>
        <ng-container *ngIf="rtmpSessions">
            <ng-container *ngIf="rtmpSessions.length === 0">
                <p>
                    <em>There are no active connections to the RTMP bay.</em>
                </p>
            </ng-container>
            <ng-container *ngFor="let session of rtmpSessions">
                <div 
                    class="rtmp-session" 
                    [class.publishing]="session.isPublishing" 
                    [class.idling]="session.isIdling" 
                    [class.playing]="!session.isIdling && session.playStreamPath"
                    >
                    <div class="publish-path">
                        <span class="tag identifier">
                            <ng-container *ngIf="session.deviceName">
                                {{session.deviceName}}
                            </ng-container>
                            <ng-container *ngIf="!session.deviceName">
                                {{!session.isLocal ? 'Local' : session.ip}}
                            </ng-container>
                        </span>
                        
                        <ng-container *ngIf="!session.isPublishing">
                            
                            <ng-container *ngIf="session.playStreamPath">
                                <ng-container *ngIf="session.isIdling">
                                    is waiting for
                                </ng-container>
                                <ng-container *ngIf="!session.isIdling">
                                    is receiving
                                </ng-container>
                                <span class="tag identifier">
                                    {{session.playStreamPath}}
                                </span>
                                
                                <ng-container *ngIf="!session.isIdling">
                                    <ng-container *ngIf="getPublisherOfPath(session.playStreamPath) as publisher">
                                        from 
                                        <span class="tag identifier">
                                            {{publisher.deviceName || publisher.ip}}
                                        </span>
                                    </ng-container>
                                </ng-container>

                            </ng-container>
                            
                            <ng-container *ngIf="!session.playStreamPath">
                                is idle
                            </ng-container>
                            
                        </ng-container>

                        <ng-container *ngIf="session.isPublishing">
                            is publishing
                            <span class="tag identifier">{{session.publishStreamPath}}</span>
                        </ng-container>

                        <div class="spacer"></div>

                        <button mat-raised-button color="primary" *ngIf="session.isPublishing" (click)="showPreviewOptions(session)">
                            <mat-icon>play_arrow</mat-icon>
                            Preview
                        </button>

                        <button mat-icon-button [matMenuTriggerFor]="rtmpSessionOverflowMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                    </div>

                    <div class="details">
                        <ul class="facts">
                            <li *ngIf="session.deviceName">
                                <code>
                                    {{!session.isLocal ? 'Local' : session.ip}}
                                </code>
                            </li>
                            <li>Clock: <code>{{session.clock}}</code></li>
                            <li>ID: <code>{{session.id}}</code></li>
                            
                            <li *ngIf="session.isPublishing">
                                {{session.videoWidth}}x{{session.videoHeight}}@{{session.videoFps}}fps
                                    [{{session.videoCodecName || '--'}} / {{session.audioCodecName || '--'}}]
                            </li>

                            <li>Connected since {{session.connectTime | date : 'short'}}</li>
                        </ul>
                    </div>
                </div>
                
                <div class="players-list" *ngIf="session.isPublishing">
                    <div class="property">
                        <label>Receivers:</label>
                    </div>
                    <ul>
                        <li *ngFor="let playerId of session.players">
                            <ng-container *ngIf="getRtmpSessionById(playerId) as player; else unknownPlayer">
                                {{player.deviceName || player.ip}}
                            </ng-container>
                            <ng-template #unknownPlayer>
                                {{playerId}}
                            </ng-template>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </ng-container>
    </mat-tab>
    <mat-tab label="Pipelines">
        <ng-container *ngIf="!transcodingSessions">
            <mat-spinner></mat-spinner>
        </ng-container>
        <ng-container *ngIf="transcodingSessions">
            <ng-container *ngIf="transcodingSessions.length === 0">
                <p>
                    <em>There are no active pipelines.</em>
                </p>
            </ng-container>
            <div class="transcoding-session" *ngFor="let session of transcodingSessions">
                <div class="publish-path">
                    {{session.description.name}}: 
                    <div class="tag identifier">
                        <code>{{session.description.input}}</code>
                    </div>

                    <mat-icon>arrow_forward</mat-icon>

                    <div class="tag" *ngFor="let output of session.description.outputs">
                        {{output.type}}
                        <ng-container *ngIf="output.publishPath">
                            publish on {{output.publishPath}}
                        </ng-container>
                    </div>

                    <ng-container *ngIf="session.description.publishPath">
                        publish on 
                        <span class="tag identifier">
                            {{session.description.publishPath}}
                        </span>
                    </ng-container>
                </div>
                <div class="property">
                    <label>Input</label>
                    <code>{{session.inputDescription.join(' ')}}</code>
                </div>
                <div class="property">
                    <label>ffmpeg</label>
                    <code>{{session.ffmpegCommandLine.join(' ')}}</code>
                </div>
                <div class="property">
                    <label>ID</label>
                    <code>{{session.id}}</code>
                </div>
                <div class="property">
                    <label>Pipeline ID</label>
                    <code>{{session.description.id}}</code>
                </div>
            </div>
        </ng-container>
    </mat-tab>
</mat-tab-group>