import { Injectable } from '@angular/core';
import { BackendSession } from './backend.service';

@Injectable()
export class BackendSessionRef {
    constructor(factoryOrValue : (() => BackendSession) | BackendSession) {

        if (typeof factoryOrValue === 'function')
            this.factory = factoryOrValue;
        else
            this.factory = () => factoryOrValue;
    }

    private factory : () => BackendSession;

    get session() {
        return this.factory();
    }
}