import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material';
import { PipelinesService } from './pipelines.service';
import { HomeComponent } from './home/home.component';
import { SettingsService } from './settings.service';
import { BackendService } from './backend.service';
import { SettingsComponent } from './settings/settings.component';
import { MonitorComponent } from './monitor/monitor.component';
import { RestartPipelineAfterSaveDialogComponent } from './restart-pipeline-after-save-dialog.component';
import { PipelineViewComponent } from './pipeline-view/pipeline-view.component';
import { InputEditorComponent } from './input-editor/input-editor.component';
import { OutputEditorComponent } from './output-editor/output-editor.component';
import { ShellService } from './shell.component';
import { FFMatrixPropertyEditorComponent } from './ff-matrix-property-editor/ff-matrix-property-editor.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { PipelineEditorComponent } from './pipeline-editor/pipeline-editor.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServersListComponent } from './servers-list/servers-list.component';
import { ServerEditorComponent } from './server-editor/server-editor.component';
import { ServerHomeComponent } from './server-home/server-home.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClusterViewComponent } from './cluster-view/cluster-view.component';
import { ClusterRegisterDialogComponent } from './cluster-register-dialog/cluster-register-dialog.component';
import { FFCodecEditorComponent } from './ff-codec-editor/ff-codec-editor.component';
import { PreviewComponent } from './preview/preview.component';
import { WorkflowViewComponent } from './workflow-view/workflow-view.component';
import { WorkflowEditorComponent } from './workflow-editor/workflow-editor.component';
import { ClientSettingsComponent } from './client-settings/client-settings.component';
import { AboutComponent } from './about/about.component';
import { LicensingModule } from '@astronautlabs/licensing';
import { AngularPlatform } from '@alterior/platform-angular';
import { DiazoModule } from 'diazo';
import { ChassisModule } from '@astronautlabs/chassis';
import { AngularFireModule } from '@angular/fire';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from '../environments/environment';
import { OmnisearchPipe } from './omnisearch.pipe';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    NotFoundComponent,
    HomeComponent,
    PipelineEditorComponent,
    WorkflowEditorComponent,
    SettingsComponent,
    MonitorComponent,
    WorkflowViewComponent,
    PipelineViewComponent,
    InputEditorComponent,
    OutputEditorComponent,
    RestartPipelineAfterSaveDialogComponent,
    ServersListComponent,
    ServerEditorComponent,
    ServerHomeComponent,
    ClusterViewComponent,
    ClusterRegisterDialogComponent,
    PreviewComponent,
    ClientSettingsComponent,
    OmnisearchPipe,

    FFMatrixPropertyEditorComponent,
    FFCodecEditorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    DiazoModule,

    AngularFireModule.initializeApp(environment.firebase),
    MarkdownModule.forRoot(),
    ChassisModule.configure({
      name: 'LiveFire',
      logoUrl: '/assets/logo.svg',
      summary: 'Build media processing flows using graphs',
      features: <any[]>[
        {
          id: 'workflows',
          name: 'Workflows',
          showInFeatures: true,
          showInMatrix: true,
          summary: 'Build powerful media processing workflows',
          skus: [ 'creator', 'professional', 'enterprise' ],
        },
        {
          id: 'pipelines',
          name: 'Pipelines',
          summary: 'Create live media processing workflows',
          skus: [ 'creator', 'professional', 'enterprise' ]
        },
        {
          id: 'nodes',
          name: 'Nodes',
          summary: 'LiveFire uses _nodes_ to perform compute and media processing. You must set up at least one node.',
          skus: <any>{
            community: {
              description: 'One',
              value: 1
            },
            creator: {
              description: 'Up to 3',
              value: 3
            },
            professional: {
              description: 'Unlimited',
              value: 0
            },
            enterprise: {
              description: 'Unlimited',
              value: 0
            }
          }
        }
      ],
      skus: <any[]>[
        {
          id: 'community',
          name: 'Community',
          rank: 0,
          showInMatrix: true,
          summary: 'The core features of LiveFire at no cost',
          support: 'Community',
          price: 'Free'
        },
        {
          id: 'creator',
          name: 'Creator',
          rank: 1,
          showInMatrix: true,
          summary: 'Tailored for the live streaming and video production needs of modern content creators',
          support: `
            9AM-5PM PST  
            _Mon-Fri_
          `,
          price: `
            $16/mo  
            _-or-_  
            $160/yr
          `
        },
        {
          id: 'professional',
          name: 'Professional',
          rank: 2,
          showInMatrix: true,
          summary: `All of LiveFire's best features at an affordable price`,
          support: `
            9AM-5PM PST  
            _Mon-Fri_
          `,
          price: `
            $20/mo  
            _-or-_  
            $200/yr
          `
        },
        {
          id: 'enterprise',
          name: 'Enterprise',
          rank: 3,
          showInMatrix: true,
          summary: 'Contact us to tailor a plan just right for your organization',
          support: `
            24x7 available
          `,
          price: `
            Contact us
          `
        },
      ],
      promotionalLicenseAvailability: [],
      faqs: [],
      version: 'Alpha',
      release: '1.0.0alpha1',
      pages: {
        pricing: {
          summary: '',
          callToActionText: 'Try it now!',
          callToActionUrl: '/'
        }
      }
    }),

    MonacoEditorModule.forRoot({
      onMonacoLoad() {
        monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
          allowNonTsExtensions: true,
          experimentalDecorators: true,
          emitDecoratorMetadata: true,
          target: monaco.languages.typescript.ScriptTarget.ESNext,
          module: monaco.languages.typescript.ModuleKind.CommonJS,
          moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
          sourceMap: true,
          typeRoots: [ 'inmemory://@types' ],
          declaration: true
        });

        setTimeout(() => {
          console.log(`Installing types...`);
          let typings = {
            'rxjs': 'https://unpkg.com/rxjs@6.5.2/index.d.ts',
            '@angular/core': 'https://unpkg.com/@angular/core@8.1.2/core.d.ts'
          };
    
          Object.keys(typings).map(async (importName) => {
            let url = typings[importName];
    
            try {
              let response = await fetch(url);
              let body = await response.text();
              console.info(`Loaded types for '${importName}' from ${url}, ${body.length} bytes`);
              
              //console.log(`TYPES FOR ${importName}:`);
              //console.log(body);

              monaco.languages.typescript.typescriptDefaults.addExtraLib(
                `
                declare module "${importName}" {
                  ${body}
                }
                `, 
                `inmemory://@types/${url.replace(/^https:\/\//, '')}`
              );
            } catch (e) {
              console.error(`Failed to load types for '${importName}' from ${url}: ${e.message || e}`);
            }
          });
        });
      }
    }),
    NgxChartsModule
  ],
  providers: [
    ShellService,
    BackendService,
    SettingsService,
    PipelinesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}
