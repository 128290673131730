import { Component, Optional, Inject } from '@angular/core';
import { Backend, BackendService } from '../backend.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    templateUrl: './server-editor.component.html',
    styleUrls: ['./server-editor.component.scss']
})
export class ServerEditorComponent {
    constructor(
        private router : Router,
        private backendService : BackendService,
        @Inject(MAT_DIALOG_DATA) @Optional()
        private data : any,
        private route : ActivatedRoute,
        private matDialogRef : MatDialogRef<ServerEditorComponent>
    ) {

    }
    server : Backend;
    
    ngOnInit() {
        if (this.data && this.data.id) {
            this.loadServer(this.data.id);
        } else {
            this.route.paramMap.subscribe(params => {
                this.loadServer(params.get('id'));
            });
        }
    }

    loadServer(id : string) {
        if (id === 'new') {
            this.server = {
                name: '',
                default: false,
                url: ''
            };
        } else {
            this.server = this.backendService.backends.find(x => x.id === id);
        }
    }

    save() {
        let backend = this.backendService.addBackend(this.server);
        this.router.navigateByUrl(`/servers/${backend.id}`);
        this.matDialogRef.close();
    }
}