import { Component, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { SettingsService } from '../settings.service';
import { Accessor, DiazoGraph } from 'diazo';
import * as uuid from 'uuid/v4';
import { SubSink } from 'subsink';
import { Product } from '@astronautlabs/chassis';
import { EntitlementsService, LicensingOptions } from '@astronautlabs/licensing';

@Component({
    templateUrl: './client-settings.component.html',
    styleUrls: ['./client-settings.component.scss']
})
export class ClientSettingsComponent {
    constructor(
        private settingsService : SettingsService,
        readonly licensingOptions : LicensingOptions
    ) {
        this.manipulator = new Proxy({}, {
            set: (target, key, value, receiver) => {
                this.accessor.set([this.config], key.toString(), value);
                this.settingsService.save();
                return true;
            },

            get: (target, key, receiver) => {
                return this.accessor.get([this.config], key.toString());
            }
        });
    }

    get features() {
        return this.licensingOptions.features;
    }

    get skus() {
        return this.licensingOptions.skus;
    }
    
    accessor = new Accessor();
    manipulator : any;

    sampleJSON = JSON.stringify({
        type: 'document',
        name: 'Sample JSON',
        description: 'This allows you to preview the selected editor theme'
    }, undefined, 2);

    sampleGraph : DiazoGraph = {
        nodes: [
            {
                id: 'stuff1',
                data: {
                    unit: 'stuff'
                },
                label: 'Stream Published',
                slots: [
                    {
                        id: 'output',
                        type: 'output',
                        label: 'Output'
                    }
                ],
                style: 'inline',
                profile: 'slim'
            },
            {
                id: 'stuff2',
                data: {
                    unit: 'stuff'
                },
                
                x: 150,
                y: 50,
                positionDeltaX: 0,
                positionDeltaY: 0,

                label: 'Branch',
                slots: [
                    {
                        id: 'input',
                        type: 'input',
                        label: 'Input'
                    },
                    {
                        id: 'true',
                        type: 'output',
                        label: 'True'
                    },
                    {
                        id: 'false',
                        type: 'output',
                        label: 'False'
                    }
                ],
                style: 'inline',
                profile: 'slim'
            },
            {
                id: 'cueOut1',
                data: {
                    
                },
                slots: [
                    {
                        id: 'input',
                        type: 'input',
                        label: 'Execute'
                    }
                ],
                label: 'Send Message',
                x: 300,
                y: 25,
                style: 'inline',
                profile: 'slim'
            }
        ],
        edges: [
            {
                fromNodeId: 'stuff1',
                fromSlotId: 'output',
                toNodeId: 'stuff2',
                toSlotId: 'input',
                valid: true
            },
            {
                fromNodeId: 'stuff2',
                fromSlotId: 'true',
                toNodeId: 'cueOut1',
                toSlotId: 'input',
                valid: true
            }
        ]
    }
    
    subsink = new SubSink();

    ngOnInit() {
        setTimeout(() => this.selectedTabIndex = 0);

        this.subsink.add(
            this.settingsService.configChanged.subscribe(() => {
                this.editorOptions = {
                    theme: this.settingsService.editorTheme, 
                    language: 'json',
                    automaticLayout: true
                };
                
                this.tsEditorOptions = {
                    theme: 'vs-dark', 
                    language: 'typescript',
                    automaticLayout: true
                };        
            })
        );
    }

    ngOnDestroy() {
        this.subsink.unsubscribe();
    }

    get config() {
        return this.settingsService.config;
    }

    set config(value) {
        this.settingsService.config = value;
    }

    headerColors = [
        { id: 'default', name: 'Default', logoColor: 'orange', textColor: 'white', backgroundColor: 'black' },
        { id: 'grayscale', name: 'Grayscale', logoColor: '#666', textColor: '#ccc', backgroundColor: '#333' },
        { id: 'ocean', name: 'Ocean', logoColor: '#6292ff', textColor: '#c0d3ff', backgroundColor: '#2c62a6' },
        { id: 'bull', name: 'Bull', logoColor: 'white', textColor: 'white', backgroundColor: 'maroon' },
        { id: 'surf', name: 'Surf', logoColor: '#337d3f', textColor: '#006d2c', backgroundColor: '#77b876' },
        { id: 'creamsicle', name: 'Creamsicle', logoColor: '#8f3b00', textColor: '#8f3b00', backgroundColor: '#ff9f4d' },
        { id: 'heart', name: 'Heart', logoColor: '#ff1b65', textColor: '#ff1b65', backgroundColor: 'pink' },
        { id: 'royal', name: 'Royal', logoColor: 'rgb(226, 109, 255)', textColor: 'rgb(226, 109, 255)', backgroundColor: 'purple' }
    ];

    editorThemes = [
        { name: 'Follow Shell Theme', value: 'follow' },
        { name: 'VS', value: 'vs' },
        { name: 'VS Dark', value: 'vs-dark' },
        { name: 'Black (High Contrast)', value: 'hc-black' }
    ];

    code : boolean = false;

    private editorOptions;
    private tsEditorOptions;

    @ViewChild('tabs')
    tabs : MatTabGroup;

    selectedTabIndex : number;

    get activeTab() {
        if (!this.tabs)
            return undefined;
        
        return this.tabs._tabs.toArray()[this.selectedTabIndex];
    }

    get configJSON() {
        if (!this.config)
            return null;
        
        return JSON.stringify(this.config, undefined, 2);
    }

    set configJSON(value) {
        if (!this.config)
            return;
        
        Object.assign(this.config, JSON.parse(value));
    }
}