<ng-container *ngIf="!settings">
    <div style="min-height: 50vh; width: 100%; display: flex; align-items: center; justify-content: center;">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>
<ng-container *ngIf="settings">
    <mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="RTMP">
            <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Port</mat-label>
                    <input type="number" matInput [(ngModel)]="settings.rtmp.port" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Chunk Size</mat-label>
                    <input type="number" matInput [(ngModel)]="settings.rtmp.chunkSize" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>GOP Cache</mat-label>
                    <input type="number" matInput [(ngModel)]="settings.rtmp.gopCache" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Ping</mat-label>
                    <input type="number" matInput [(ngModel)]="settings.rtmp.ping" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Ping Timeout</mat-label>
                    <input type="number" matInput [(ngModel)]="settings.rtmp.pingTimeout" />
                </mat-form-field>
            </div>
        </mat-tab>
        <mat-tab label="Clock">
            
        </mat-tab>
        <mat-tab label="Transcoding">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>FFMPEG Path</mat-label>
                <input type="text" matInput [(ngModel)]="settings.ffmpeg" />
            </mat-form-field>
        </mat-tab>
        <mat-tab label="HTTP">
            <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Media Root</mat-label>
                    <input type="text" matInput [(ngModel)]="settings.http.mediaroot" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Web Root</mat-label>
                    <input type="text" matInput [(ngModel)]="settings.http.webroot" />
                </mat-form-field>
            </div>
            
        </mat-tab>
        <mat-tab label="JSON" #jsonTab>
            <ng-container *ngIf="activeTab === jsonTab">
                    <ngx-monaco-editor 
                        [options]="editorOptions" 
                        [(ngModel)]="settingsJSON"></ngx-monaco-editor>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
    <div class="actions">
        <button mat-raised-button color="primary" (click)="save()">
            <mat-icon>save</mat-icon>
            Save
        </button>
    </div>
</ng-container>