import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    template: `
        This pipeline is currently active. Do you wish to restart it to apply 
        the new settings? Depending on the pipeline, this may cause interruptions,
        playback failure, and/or disconnects to any upstream viewers.

        <div class="buttons">
            <div class="spacer"></div>
            <a mat-button (click)="cancel()">Cancel</a>
            <a mat-raised-button color="primary" (click)="saveAndRestart()">Save &amp; Restart</a>
            <a mat-raised-button color="primary" (click)="save()">Save</a>
        </div>
    `,
    styles: [
        `
        :host {
            width: 30em;
            display: block;
        }

        .buttons {
            display: flex;
        }

        .buttons a {
            margin-left: 0.5em;
        }

        .spacer {
            flex-grow: 1;
        }
        `
    ]
})
export class RestartPipelineAfterSaveDialogComponent {
    constructor(
        private matDialogRef : MatDialogRef<RestartPipelineAfterSaveDialogComponent>
    ) {
    }

    cancel() {
        this.matDialogRef.close('cancel');
    }

    save() {
        this.matDialogRef.close('save-only');
    }

    saveAndRestart() {
        this.matDialogRef.close('restart');
    }
}