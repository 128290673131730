declare let require;

import { Component } from '@angular/core';
import { DiazoGraph } from 'diazo';

const PKG = require('../../../package.json');

@Component({
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent {
    get pkg() {
        return PKG;
    }

    graph : DiazoGraph = { nodes: [], edges: [] };
}