import { Component } from "@angular/core";
import { BackendService, BackendSession } from '../backend.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ClusterRegisterDialogComponent } from '../cluster-register-dialog/cluster-register-dialog.component';

@Component({
    templateUrl: './cluster-view.component.html',
    styleUrls: ['./cluster-view.component.scss']
})
export class ClusterViewComponent {
    constructor(
        private route : ActivatedRoute,
        private backendService : BackendService,
        private matDialog : MatDialog
    ) {

    }

    backend : BackendSession;

    ngOnInit() {
        this.route.paramMap.subscribe(async params => {
            this.backend = this.backendService.getSession(params.get('server'));
            let settings = await this.backend.getSettings();
            this.cluster = settings.cluster;

            if (!this.cluster) {
                this.cluster = {
                    role: 'primary',
                    nodes: []
                }
            }
        });
    }

    cluster : any;

    showRegister() {
        this.matDialog.open(ClusterRegisterDialogComponent);
    }
}