<div class="loading" *ngIf="!session && !allCodecs">
    <mat-spinner color="accent"></mat-spinner>
</div>
<ng-container *ngIf="session">
    <ng-container *ngIf="!codecSelectorVisible">
        
        <ng-container *ngIf="!encoder">
            <a mat-raised-button color="primary" href="javascript:;" (click)="showCodecSelector()">
                <mat-icon>code</mat-icon>
                Choose Codec
            </a>

        </ng-container>
        <ng-container *ngIf="encoder">
            <div class="property multi-line">
                <label>{{property.label}}</label>

                <div>
                    <div *ngIf="selectedCodec">
                        <code>{{selectedCodec.id}}</code> {{selectedCodec.name}}
                        <ng-container *ngIf="selectedCodec.id != encoder">
                            (using {{encoder}})
                        </ng-container>
                    </div>
                    <div *ngIf="!selectedCodec">
                        <code>{{encoder}}</code> [cannot find details]
                    </div>

                    <a mat-button href="javascript:;" (click)="showCodecSelector()">Change</a>
                </div>
        
            </div>

            <div *ngIf="encoder === 'libx264'">
                <div class="property">
                    <label>General</label>
                </div>

                <div class="fields-3">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Preset</mat-label>
                        <mat-select [(ngModel)]="encoderParams.preset">
                            <mat-option value="ultrafast">Ultra Fast</mat-option>
                            <mat-option value="superfast">Super Fast</mat-option>
                            <mat-option value="veryfast">Very Fast</mat-option>
                            <mat-option value="faster">Faster</mat-option>
                            <mat-option value="fast">Fast</mat-option>
                            <mat-option value="medium">Medium</mat-option>
                            <mat-option value="slow">Slow</mat-option>
                            <mat-option value="Slower">Slower</mat-option>
                            <mat-option value="veryslow">Very Slow</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Fine Tuning</mat-label>
                        <mat-select [(ngModel)]="encoderParams.tune">
                            <mat-option value="film">Film</mat-option>
                            <mat-option value="animation">Animation</mat-option>
                            <mat-option value="grain">Grain</mat-option>
                            <mat-option value="stillimage">Still Image</mat-option>
                            <mat-option value="fastdecode">Fast Decode</mat-option>
                            <mat-option value="zerolatency">Zero Latency</mat-option>
                            <mat-option value="psnr">PSNR</mat-option>
                            <mat-option value="ssim">SSIM</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Profile</mat-label>
                        <mat-select [(ngModel)]="encoderParams['profile:v']" placeholder="Auto">
                            <mat-option value="∅">Auto</mat-option>
                            <mat-option value="baseline">Baseline</mat-option>
                            <mat-option value="main">Main</mat-option>
                            <mat-option value="high">High</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="property">
                    <label>Bitrate Control: Constant Rate Factor</label>
                </div>

                <div class="fields-2">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>CRF</mat-label>
                        <input matInput placeholder="23" type="number" min="-1" max="51" [(ngModel)]="encoderParams['crf']" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Max CRF</mat-label>
                        <input matInput placeholder="23" type="number" min="-1" max="51" [(ngModel)]="encoderParams['crf_max']" />
                    </mat-form-field>
                </div>
                
                <div class="property">
                    <label>Bitrate Control: Constant Quantization</label>
                </div>

                <div class="fields-3">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>QP</mat-label>
                        <input matInput placeholder="23" type="number" step="1" min="-1" max="4294967296" [(ngModel)]="encoderParams['qp']" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Blur</mat-label>
                        <input matInput type="number" min="-1" [(ngModel)]="encoderParams['cplxblur']" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Chroma Offset</mat-label>
                        <input matInput type="number" [(ngModel)]="encoderParams['chromaoffset']" />
                    </mat-form-field>
                </div>

                <div class="property">
                    <label>Bitrate Constraints</label>
                </div>

                <div class="fields-3">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Minimum</mat-label>
                        <input matInput type="text" [(ngModel)]="encoderParams['minrate']" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Buffer Size</mat-label>
                        <input matInput type="text" [(ngModel)]="encoderParams['bufsize']" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Maximum</mat-label>
                        <input matInput type="text" [(ngModel)]="encoderParams['maxrate']" />
                    </mat-form-field>
                </div>

                <div class="property">
                    <label>Bitrate Control: General</label>
                </div>

                <div class="fields-2">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Lookahead</mat-label>
                        <input matInput type="number" [(ngModel)]="encoderParams['rc-lookahead']" />
                        <mat-hint>(in frames)</mat-hint>
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Hypothetical Reference Decoder (HRD)</mat-label>
                        <mat-select [(ngModel)]="encoderParams['nal-hrd']" placeholder="Auto">
                            <mat-option [value]="'-1'">Auto</mat-option>
                            <mat-option [value]="'none'">None</mat-option>
                            <mat-option [value]="'cbr'">CBR</mat-option>
                            <mat-option [value]="'vbr'">VBR</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="property">
                    <label>Adaptive Quantization</label>
                </div>

                <div class="fields-2">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Mode</mat-label>
                        <mat-select [(ngModel)]="encoderParams['aq-mode']" placeholder="Auto">
                            <mat-option [value]="'-1'">Auto</mat-option>
                            <mat-option [value]="'none'">None</mat-option>
                            <mat-option [value]="'variance'">Variance</mat-option>
                            <mat-option [value]="'autovariance'">Auto-variance</mat-option>
                            <mat-option [value]="'autovariance-biased'">Auto-variance with bias to dark scenes</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Strength</mat-label>
                        <input matInput type="number" [(ngModel)]="encoderParams['aq-strength']" />
                    </mat-form-field>
                </div>

                <div class="property">
                    <label>Psychovisual Optimization</label>
                </div>

                <div class="fields-n">
                    <mat-checkbox [(ngModel)]="encoderParams['psy']">Enabled &nbsp;</mat-checkbox>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Strength</mat-label>
                        <input matInput type="text" [(ngModel)]="encoderParams['psy-rd']" />
                        <mat-hint>(psy-rd):(psy-trellis)</mat-hint>
                    </mat-form-field>
                </div>

                <div class="property">
                    <label>B-Frames</label>
                </div>

                <div class="fields-2">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Strategy</mat-label>
                        <mat-select [(ngModel)]="encoderParams['b-strategy']" placeholder="(1) Balanced">
                            <mat-option [value]="'-1'">Default</mat-option>
                            <mat-option [value]="'0'">(0) Fast</mat-option>
                            <mat-option [value]="'1'">(1) Balanced</mat-option>
                            <mat-option [value]="'2'">(2) Accurate</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Pyramid</mat-label>
                        <mat-select [(ngModel)]="encoderParams['b-pyramid']" placeholder="Default">
                            <mat-option [value]="'-1'">Default</mat-option>
                            <mat-option [value]="'none'">None</mat-option>
                            <mat-option [value]="'strict'">Strict</mat-option>
                            <mat-option [value]="'normal'">Normal</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="fields-2">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Bias</mat-label>
                        <input matInput type="number" [(ngModel)]="encoderParams['b-bias']" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Prediction Mode</mat-label>
                        <mat-select [(ngModel)]="encoderParams['direct-pred']" placeholder="Default">
                            <mat-option [value]="'-1'">Default</mat-option>
                            <mat-option [value]="'none'">None</mat-option>
                            <mat-option [value]="'spatial'">Spatial</mat-option>
                            <mat-option [value]="'temporal'">Temporal</mat-option>
                            <mat-option [value]="'auto'">Auto</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="property">
                    <label>Weighted Prediction</label>
                </div>

                <div class="fields-3">
                    <mat-checkbox [(ngModel)]="encoderParams['weightb']">B-Frames &nbsp; </mat-checkbox>

                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>P-Frames</mat-label>
                        <input matInput type="text" [(ngModel)]="encoderParams['wpredp']" />
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Mode</mat-label>
                        <mat-select [(ngModel)]="encoderParams['weightp']" placeholder="Auto">
                            <mat-option [value]="'-1'">Auto</mat-option>
                            <mat-option [value]="'none'">None</mat-option>
                            <mat-option [value]="'strict'">Strict</mat-option>
                            <mat-option [value]="'normal'">Normal</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div>
                </div>


                <div class="property">
                    <label>Encoding</label>
                </div>

                <div class="fields-3">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Motion Estimation</mat-label>
                        <mat-select [(ngModel)]="encoderParams['me_method']" placeholder="Auto">
                            <mat-option [value]="'-1'">Auto</mat-option>
                            <mat-option [value]="'dia'">Diamond</mat-option>
                            <mat-option [value]="'hex'">Hexagon</mat-option>
                            <mat-option [value]="'umh'">Uneven Multi-Hex</mat-option>
                            <mat-option [value]="'esa'">Exhaustive</mat-option>
                            <mat-option [value]="'tesa'">SATD Exhaustive</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Coder</mat-label>
                        <mat-select [(ngModel)]="encoderParams['coder']" placeholder="Auto">
                            <mat-option [value]="'default'">Default</mat-option>
                            <mat-option [value]="'cavlc'">(CAVLC) Context-adaptive variable-length coding</mat-option>
                            <mat-option [value]="'cabac'">(CABAC) Context-adaptive binary arithmetic coding</mat-option>
                            <mat-option [value]="'vlc'">(VLC) Variable-length coding</mat-option>
                            <mat-option [value]="'ac'">(AC) Arithmetic coding</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>AVC-Intra class</mat-label>
                        <input matInput type="number" [(ngModel)]="x264params['keyint']" placeholder="Auto" />
                    </mat-form-field>
                </div>

                <div>
                    <mat-checkbox [(ngModel)]="x264params['force-cfr']">Force CFR</mat-checkbox>
                </div>


                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['fastfirstpass']">Fast first pass</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['a53cc']">Use A53 Closed Captions</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['ssim']">Calculate and print SSIM stats</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['intra-refresh']">Use periodic Intra Refresh instead of IDR frames</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['bluray-compat']">Use Bluray compatibility workarounds</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['mixed-refs']">One reference per partition, as opposed to one reference per macroblock</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['8x8dct']">High profile 8x8 transcofrm</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['fast-pskip']">Fast PSkip</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['aud']">Use access unit delimiters</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['mbtree']">Use macroblock tree rate control</mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [(ngModel)]="encoderParams['forced-idr']">If forcing keyframes, force them as IDR frames.</mat-checkbox>
                </div>

                <br/>
                
                <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Slice Max Size</mat-label>
                        <input matInput type="number" [(ngModel)]="encoderParams['slice-max-size']" />
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Partitions</mat-label>
                        <input matInput type="text" [(ngModel)]="encoderParams['partitions']" />
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Deblock</mat-label>
                        <input matInput type="text" [(ngModel)]="encoderParams['deblock']" />
                    </mat-form-field>
                </div>

                <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>x264 Parameters</mat-label>
                        <input matInput type="text" [(ngModel)]="encoderParams['x264-params']" />
                    </mat-form-field>
                </div>
            </div>

        </ng-container>
                
    </ng-container>
    <ng-container *ngIf="codecSelectorVisible">
        <div class="property">
            <label>{{property.label}}</label>
        </div>
        
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-icon matPrefix>search</mat-icon>
    
            <mat-label>
                Search
            </mat-label>
    
            <input #codecSearchBox type="text" matInput [(ngModel)]="codecSearchQuery" />
            
            <button mat-icon-button matSuffix *ngIf="codecSearchQuery" (click)="codecSearchQuery = ''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>    

        <div class="hint" *ngIf="codecSearchQuery === ''">
            <strong>Search to begin</strong>. {{codecs.length}} codecs available
        </div>
        <div class="codecs" *ngIf="codecSearchQuery !== ''">
            <div class="codec" *ngFor="let codec of codecs | omnisearch : codecSearchQuery">
                <ng-container *ngIf="!codec">
                    <h1>No matches!</h1>
                </ng-container>
                <ng-container *ngIf="codec">
                    <h1>{{codec.name}}</h1>
                    <ul class="facts">
                        <li><code>{{codec.id}}</code></li>
                        <ng-container *ngIf="codec.supportsEncoding == codec.supportsDecoding">
                            <li>Encoder/Decoder</li>
                        </ng-container>
                        <ng-container *ngIf="codec.supportsEncoding != codec.supportsDecoding">
                            <li *ngIf="codec.supportsEncoding">Encoder</li>
                            <li *ngIf="codec.supportsDecoding">Decoder</li>
                        </ng-container>
                        <li *ngIf="codec.intraFrameOnly">IFF</li>
                        <ng-container *ngIf="codec.supportsLossyCompression == codec.supportsLosslessCompression">
                            <li>Lossy/Lossless</li>
                        </ng-container>
                        
                        <ng-container *ngIf="codec.supportsLossyCompression != codec.supportsLosslessCompression">
                            <li *ngIf="codec.supportsLossyCompression">Lossy</li>
                            <li *ngIf="codec.supportsLosslessCompression">Lossless</li>
                        </ng-container>
                    </ul>

                    <div class="encoders">
                        <a class="encoder" *ngFor="let encoder of codec.encoders" href="javascript:;" (click)="selectCodec(codec, encoder)">
                            <code>{{encoder}}</code>
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-container>