import { Component } from '@angular/core';
import { BackendService } from '../backend.service';
import { Router } from '@angular/router';

@Component({
    template: '',
    styles: ['']
})
export class HomeComponent {
    constructor(
        private backendService : BackendService,
        private router : Router
    ) {
    }

    ngOnInit() {
        let defaultBackend = this.backendService.backends.find(x => x.default);
        if (defaultBackend)
            this.router.navigateByUrl(`/servers/${defaultBackend.id}`);
        else
            this.router.navigateByUrl(`/servers`);
    }
}