<div class="page-container">
    <h1 *ngIf="!isDialog">
        <a routerLink="/pipelines">
            Pipelines
        </a>
        &raquo;
        <ng-container *ngIf="!pipeline.id">New</ng-container>
        <ng-container *ngIf="pipeline.id">
            <a routerLink="/pipelines/{{pipeline.id}}">{{pipeline.name}}</a>
            &raquo;
            Edit
        </ng-container>
        
    </h1>

    <div class="headline">
        <mat-form-field class="name">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="pipeline.name">
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Type</mat-label>
            <mat-select [(value)]="pipeline.executionType" placeholder="On-Demand">
                <mat-option value="reusable">Reusable</mat-option>
                <mat-option value="on-demand">On-Demand</mat-option>
                <mat-option value="continuous">Continuous</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <ng-container *ngIf="['continuous', 'on-demand'].includes(pipeline.executionType)">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Run on</mat-label>
            <mat-select [(ngModel)]="pipeline.runOn" placeholder="Primary">
                <mat-option value="primary">Primary</mat-option>
                <mat-option value="auto">Worker</mat-option>
                <mat-option value="designated">Designated Worker</mat-option>
            </mat-select>
        </mat-form-field>
    
        <div style="margin-left: 4em;" *ngIf="pipeline.runOn === 'designated'">
            
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Worker</mat-label>
                <mat-select [(ngModel)]="pipeline.runOnWorker">
                    <mat-option value="w1">Worker 1</mat-option>
                    <mat-option value="w2">Worker 2</mat-option>
                    <mat-option value="as1">Audio Stage 1</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container *ngIf="pipeline.executionType === 'reusable'">
        <ul class="slot-list" cdkDropList (cdkDropListDropped)="reorderSlots($event)">

            <li class="slot-list-item" *ngFor="let slot of pipeline.slots" cdkDrag>
                <div class="header">
                    <button mat-icon-button cdkDragHandle>
                        <mat-icon>menu</mat-icon>
                    </button>

                    <mat-form-field class="type-selector" appearance="outline" floatLabel="always">
                        <mat-label>Type</mat-label>
                        <mat-select [(ngModel)]="slot.type">
                            <mat-option value="input">Input</mat-option>
                            <mat-option value="output">Output</mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <mat-form-field class="label" appearance="outline" floatLabel="always">
                        <mat-label>Label</mat-label>
                        <input type="text" matInput [(ngModel)]="slot.label" />
                    </mat-form-field>

                    <mat-form-field class="value-sector" appearance="outline" floatLabel="always" *ngIf="slot.value">
                        <mat-label>Value</mat-label>
                        <mat-select [(ngModel)]="slot.value.type">
                            <mat-option value="none">None</mat-option>
                            <mat-option value="wildcard">Wildcard</mat-option>
                            <mat-option *ngFor="let type of valueTypes" [value]="type.id">
                                {{type.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="details">
                    <ng-container *ngIf="slot.value.type === 'wildcard'">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Wildcard Name</mat-label>
                            <input type="text" matInput [(ngModel)]="slot.value.params.templateName" />
                        </mat-form-field>
                    </ng-container>

                    <div>
                        <mat-form-field class="label" appearance="outline" floatLabel="always">
                            <mat-label>ID</mat-label>
                            <input type="text" matInput [(ngModel)]="slot.id" />
                        </mat-form-field>
                    </div>
                </div>
            </li>
        </ul>

        <div style="text-align: left;">
            <button mat-raised-button color="primary" (click)="addSlot()">
                <mat-icon>add</mat-icon>
                Add Slot
            </button>
        </div>

    </ng-container>

    <mat-divider class="save-toolbar-divider"></mat-divider>

    <div class="save-toolbar">
        <div class="spacer"></div>
        <a href="javascript:;" mat-button (click)="discard()">
            <ng-container *ngIf="isPersisted">
                Discard Changes
            </ng-container>
            <ng-container *ngIf="!isPersisted">
                Cancel
            </ng-container>
            
        </a>
        <a href="javascript:;" mat-raised-button color="primary" (click)="save()">Save</a>
    </div>
</div>