import { Component } from "@angular/core";
import { BackendService, BackendSession } from '../backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Base64 } from 'js-base64';

@Component({
    templateUrl: './monitor.component.html',
    styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent {
    constructor(
        private backendService : BackendService,
        private route : ActivatedRoute,
        private router : Router
    ) {

    }

    rtmpSessions : any[];
    rtmpStats : any;
    transcodingSessions : any[];

    backend : BackendSession;
    
    private subsink : SubSink;

    getPublisherOfPath(path : string) {
        return this.rtmpSessions.find(x => x.publishStreamPath === path);
    }

    getRtmpSessionById(id : string) {
        return this.rtmpSessions.find(x => x.id === id);
    }

    async fetch() {
        this.rtmpSessions = await this.backend.getRTMPSessions();
        this.rtmpStats = await this.backend.getRTMPStats();
        this.transcodingSessions = await this.backend.getTranscodingSessions();
    }

    showPreviewOptions(session : any) {
        let path = session.publishStreamPath;
        let input = `router://rtmp${path}`;
        this.router.navigateByUrl(
            `/servers/${this.backend.id}/preview/${Base64.toBase64(input, true)}`
        );
    }

    ngOnInit() {
        this.route.paramMap.subscribe(async params => {

            if (this.subsink) {
                this.subsink.unsubscribe();
            }

            this.subsink = new SubSink();

            let serverID = params.get('server');
            this.backend = this.backendService.getSession(serverID);

            this.fetch();

            this.subsink.add(this.backend.notifications.subscribe(notif => {

                switch (notif.type) {
                    case 'connected':
                        this.fetch();
                        break;
                    case 'rtmp:publish':
                    case 'rtmp:unpublish':
                    case 'rtmp:play':
                    case 'rtmp:session:changed':
                    case 'rtmp:session:connected':
                    case 'rtmp:session:disconnected':
                    case 'rtmp:stop': {
                        // update session...

                        let session = notif.data.session;

                        console.log(`RECEIVED session update ${notif.type} for ${session.id}`);
                        console.dir(session);

                        let existingIndex = this.rtmpSessions.findIndex(x => x.id === session.id);

                        
                        if (notif.type === 'rtmp:session:disconnected') {
                            if (existingIndex >= 0) {
                                console.log(`REMOVING ${existingIndex} [during ${notif.type} for ${session.id}]`)
                                this.rtmpSessions.splice(existingIndex, 1);
                            }
                        } else {
                            if (existingIndex >= 0) {
                                console.log('UPDATED existing session');
                                this.rtmpSessions[existingIndex] = session;
                            } else {
                                if (notif.type !== 'rtmp:stop') {
                                    console.log('ADDED new session');
                                    this.rtmpSessions.push(session);
                                }
                            }
                        }
                    }
                }

                // Specific events

                let session = notif.data.session;
                switch (notif.type) {
                    case 'rtmp:publish': {
                        let path = notif.data.path;
                        break;
                    } 
                    case 'rtmp:unpublish': {
                        let path = notif.data.path;
                        break;
                    }
                }
            }));
        });
    }
}