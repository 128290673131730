<ng-container *ngIf="entry.id">
    <h1>Edit Output</h1>
</ng-container>

<div *ngIf="!output.type">
    <ng-container *ngIf="!entry.id">
        <h1>New Output</h1>
    </ng-container>
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="output.type">
            <mat-option value="rtmp">RTMP</mat-option>
            <mat-option value="hls">HLS</mat-option>
            <mat-option value="dash">DASH</mat-option>
            <mat-option value="mpegts">MPEG-TS</mat-option>
            <mat-option value="file">File</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<ng-container *ngIf="output.type">
    <ng-container *ngIf="!entry.id">
        <h1>
            <a href="javascript:;" (click)="output.type = null">New Output</a>
            &raquo;
            {{output.type}}
        </h1>
    </ng-container>
    <div>
        <mat-form-field>
            <mat-label>Routing Path</mat-label>
            <input placeholder="/virtual/path" matInput type="text" [(ngModel)]="entry.path" />
        </mat-form-field>
    </div>
        
    <ng-container *ngIf="output.type === 'rtmp'">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>RTMP Endpoint</mat-label>
            <input placeholder="rtmp://hostname/appname/streamname" matInput type="text" [(ngModel)]="output.endpoint" />
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="output.type === 'mpegts'">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>MPEG-TS Endpoint</mat-label>
            <input placeholder="udp://hostname:port" matInput type="text" [(ngModel)]="output.endpoint" />
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="output.type === 'hls'">

        <div>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Segment Length</mat-label>
                <input type="number" matInput [(ngModel)]="output.segmentLength" />
            </mat-form-field>
            &nbsp;
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Playlist: Keep Segment Count</mat-label>
                <input type="number" matInput [(ngModel)]="output.playlistKeepSegmentCount" />
            </mat-form-field>
        </div>
        <div>
            <mat-checkbox [(ngModel)]="output.deleteSegments">Delete old segments</mat-checkbox>
        </div>
    </ng-container>
    <ng-container *ngIf="output.type === 'dash'">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Window Size</mat-label>
            <input type="number" matInput [(ngModel)]="output.windowSize" />
        </mat-form-field>
        &nbsp;
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Extra Window Size</mat-label>
            <input type="number" matInput [(ngModel)]="output.extraWindowSize" />
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="output.type === 'file'">
        <div>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Filename</mat-label>
                <input placeholder="/foo/bar.mp4" matInput type="text" [(ngModel)]="output.endpoint" />
            </mat-form-field>
        </div>
    </ng-container>

    <ng-container *ngIf="['hls', 'dash', 'mp4', 'mpegts'].includes(output.type)">
        <br/>
        <mat-divider></mat-divider>
        <br/>

        <div>
            <mat-radio-group [(ngModel)]="output.destination">
                <mat-radio-button value="serve">Serve</mat-radio-button>
                &nbsp;
                <mat-radio-button value="forward">Forward</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="output-type-options" *ngIf="output.destination === 'serve'">
            Content will be served over HTTP at:

            <mat-form-field>
                <mat-label>Publish Path</mat-label>
                <input type="text" matInput [(ngModel)]="output.publishPath" />
            </mat-form-field>
        </div>
        <div class="output-type-options" *ngIf="output.destination === 'forward'">
            Content will be forwarded to:
            <mat-form-field>
                <mat-label>Destination URL</mat-label>
                <input placeholder="http://" matInput [(ngModel)]="output.forward">
            </mat-form-field>
            <br/>

            <div class="note">
                <label>Supported URL schemes</label>
                <ul>
                    <li>http://host[:port]/path</li>
                    <li *ngIf="output.type === 'mpegts'">udp://server:port</li>
                </ul>
            </div>
        </div>
    </ng-container>
</ng-container>
<div class="actions">
    <a mat-button (click)="cancel()">Cancel</a>
    <a mat-raised-button color="primary" (click)="save()" *ngIf="output.type" [disabled]="!valid">Save</a>
</div>