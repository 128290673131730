import { Component, ViewChild } from '@angular/core';
import { SettingsService } from '../settings.service';
import { MatTabGroup } from '@angular/material/tabs';
import { BackendService, BackendSession } from '../backend.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
    constructor(
        private backendService : BackendService,
        private route : ActivatedRoute
    ) {

    }

    settings : any;

    @ViewChild('tabs')
    tabs : MatTabGroup;

    selectedTabIndex : number = 0;

    editorOptions = {
        theme: 'vs-dark', 
        language: 'json',
        automaticLayout: true
    };

    backend : BackendSession;
    
    get activeTab() {
        if (!this.tabs)
            return null;
        return this.tabs._tabs.toArray()[this.selectedTabIndex];
    }

    ngOnInit() {
        this.route.paramMap.subscribe(async params => {
            let serverID = params.get('server');
            this.backend = this.backendService.getSession(serverID);
            this.settings = await this.backend.getSettings();
        });
    }

    get settingsJSON() {
        return JSON.stringify(this.settings, undefined, 2);
    }

    set settingsJSON(value) {
        try {
            this.settings = JSON.parse(value);
        } catch (e) {
            console.warn(`Failed to parse JSON in Settings:`);
            console.warn(e);
        }
    }

    async save() {
        await this.backend.changeSettings(this.settings);
    }
}