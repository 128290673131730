import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService, Backend, BackendSession } from '../backend.service';
import { SubSink } from 'subsink';

@Component({
    templateUrl: './server-home.component.html',
    styleUrls: ['./server-home.component.scss']
})
export class ServerHomeComponent {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private backendService: BackendService
    ) {

    }

    async loadCodecs() {
        if (!this.codecs) {
            this.codecs = await this.session.getCodecs();
        }
    }

    codecFeatures = [
        { id: 'supportsDecoding', trueTooltip: 'Decoding is supported', falseTooltip: 'Decoding is not supported' }, 
        { id: 'supportsEncoding', trueTooltip: 'Encoding is supported', falseTooltip: 'Encoding is not supported' }, 
        { id: 'intraFrameOnly', trueTooltip: 'This codec only supports intra-frame coding', falseTooltip: 'This codec supports both intra-frame and inter-frame encoding' }, 
        { id: 'supportsLossyCompression', trueTooltip: 'This codec supports lossy compression', falseTooltip: 'This codec does not support lossy compression' }, 
        { id: 'supportsLosslessCompression', trueTooltip: 'This codec supports lossless compression', falseTooltip: 'This codec does not support lossless compression' }
    ];

    bytesToGB(bytes: number) {
        if (bytes > 1024 * 1024 * 1024 * 1024) {
            return `${Math.floor(bytes / (1024 * 1024 * 1024 * 1024))}TB`;
        } else if (bytes > 1024 * 1024 * 1024) {
            return `${Math.floor(bytes / (1024 * 1024 * 1024))}GB`;
        } else if (bytes > 1024 * 1024) {
            return `${Math.floor(bytes / (1024 * 1024))}MB`;
        } else if (bytes > 1024) {
            return `${Math.floor(bytes / (1024))}KB`;
        } else {
            return `${bytes}B`;
        }
    }
    hms(seconds : number) {
        let days = 0;
        let hours = 0;
        let minutes = 0;
        
        if (seconds > 60*60*24) {
            days = Math.floor(seconds / (60*60*24));
            seconds -= days*60*60*24;
        }

        if (seconds > 60*60) {
            hours = Math.floor(seconds / (60*60));
            seconds -= hours*60*60;
        }

        if (seconds > 60) {
            minutes = Math.floor(seconds / 60);
            seconds -= minutes*60;
        }

        if (days > 0)
            return `${days}:${this.zeroPad(hours)}:${this.zeroPad(minutes)}:${this.zeroPad(seconds)}`;
        
        if (hours > 0)
            return `${hours}:${this.zeroPad(minutes)}:${this.zeroPad(seconds)}`;
        
        return `${minutes}:${this.zeroPad(seconds)}`;
    }

    zeroPad(number, digits = 2) {
        let str = `${number}`;

        while (str.length < digits)
            str = '0' + str;

        return str;
    }

    memUsageData = [
        {
            name: 'Memory Usage',
            series: [],
            label: 'Memory Usage'
        }
    ]

    cpuUsageData = [
        { 
            name: 'CPU Usage',
            series: [], 
            label: 'CPU Usage' 
        }
    ];
    
    sink = new SubSink();
    codecs : any[] = null;
    codecSearchQuery : string = '';

    ngOnInit() {

        this.route.paramMap.subscribe(params => {
            let id = params.get('server');
            this.session = this.backendService.getSession(id);

            this.sink.add(this.session.statusChanged.subscribe(status => {
                this.memUsageData = [
                    {
                        name: 'Memory Usage',
                        series: this.session.memUsageData,
                        label: 'Memory Usage'
                    }
                ];

                this.cpuUsageData = [
                    {
                        name: 'CPU Usage',
                        series: this.session.cpuUsageData, 
                        label: 'CPU Usage' 
                    }
                ];
            }));

            if (!this.session) {
                this.router.navigateByUrl('/servers');
            }
        });
    }

    session: BackendSession;

    get server() {
        if (!this.session)
            return null;

        return this.session.backend;
    }
}