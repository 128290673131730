<h1>Add Server</h1>

<div>
    <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input type="text" [(ngModel)]="server.name" matInput />
    </mat-form-field>
</div>
<div>
    <mat-form-field appearance="outline">
        <mat-label>URL</mat-label>
        <input type="text" [(ngModel)]="server.url" matInput placeholder="https://example.com/" />
    </mat-form-field>
</div>

<div>
    <mat-checkbox [(ngModel)]="server.default">Default</mat-checkbox>
</div>

<br/>

<div style="text-align: right;">
    <button mat-raised-button (click)="save()" color="primary">
        <mat-icon>save</mat-icon>
        Save
    </button>
</div>