<div class="document">
    <div class="toolbar">
        <h1>Settings</h1>
        <div class="spacer"></div>
        <button mat-icon-button (click)="code = !code">
            <mat-icon>code</mat-icon>
        </button>
    </div>

    <ngx-monaco-editor 
        *ngIf="code"
        class="full-tab"
        [options]="editorOptions" 
        [(ngModel)]="configJSON"
        ></ngx-monaco-editor>

    <mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex" *ngIf="!code">
        <mat-tab label="Appearance" #appearanceTab>

            <div class="property-container split50">
                <div>
                    <div class="property">
                        <label>Header Color</label>
                    </div>
                    <div class="color-swatches">

                        <a  *ngFor="let theme of headerColors" 
                            [class.selected]="config.headerTheme.id === theme.id"
                            [style.color]="theme.backgroundColor"
                            [title]="theme.name"
                            (click)="manipulator.headerTheme = theme"
                            ></a>
                    </div>
                </div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Shell Theme</mat-label>
                    <mat-select [(ngModel)]="manipulator.theme">
                        <mat-option value="default">System Default</mat-option>
                        <mat-option value="dark">Dark</mat-option>
                        <mat-option value="light">Light</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="property-container">

                <div class="theme-controls">
                    <div class="theme-control">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Editor Theme</mat-label>
                            <mat-select [(ngModel)]="manipulator.editorTheme">
                                <mat-option *ngFor="let theme of editorThemes" [value]="theme.value">
                                    {{theme.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="editor-container" *ngIf="activeTab === appearanceTab" [style.height]="'10em'">
                            <ngx-monaco-editor 
                                [options]="editorOptions" 
                                [readonly]="true"
                                [ngModel]="sampleJSON"
                                ></ngx-monaco-editor>
                        </div>
                        <div *ngIf="activeTab !== appearanceTab">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>

                    <div class="theme-control">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Graph Theme</mat-label>
                            <mat-select [(ngModel)]="manipulator.graphTheme">
                                <mat-option value="follow">Follow Shell Theme</mat-option>
                                <mat-option value="dark">Dark</mat-option>
                                <mat-option value="light">Light</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div>
                            <dz-container [graph]="sampleGraph">
                            </dz-container>
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Account">
            <ala-license-status></ala-license-status>
        </mat-tab>
    </mat-tab-group>
</div>

<pre>{{licensingOptions | json}}</pre>