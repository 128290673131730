import { Component } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './cluster-register-dialog.component.html',
    styleUrls: ['./cluster-register-dialog.component.scss']
})
export class ClusterRegisterDialogComponent {
    constructor(
        private matDialogRef : MatDialogRef<ClusterRegisterDialogComponent>
    ) {

    }
    registrationUrl = 'http://example.com/blah';

    close() {
        this.matDialogRef.close();
    }
}