import { DiazoPropertyContext, MULTIPLE_VALUES } from 'diazo';
import { Component } from '@angular/core';

export interface FFMatrixPropertyOptions {
    squareSizes? : number[];
    minRowSize? : number;
    maxRowSize? : number;
    maxColumnSize? : number;
    minColumnSize? : number;
    matrixTypePath : string;
}

@Component({
    templateUrl: `./ff-matrix-property-editor.component.html`,
    styleUrls: [`./ff-matrix-property-editor.component.scss`],
})
export class FFMatrixPropertyEditorComponent {
    constructor(
        private context : DiazoPropertyContext
    ) {
        
    }

    get multipleValues() : boolean {
        return this.context.manipulator[this.context.property.path + '?'] === MULTIPLE_VALUES;
    }
    
    get currentValue() : number[] {
        let str : string = this.context.manipulator[this.context.property.path];

        if (!str)
            return [];
        
        return str.split(' ').map(x => parseFloat(x));
    }

    get property() {
        return this.context.property;
    }
    
    get currentType() {
        return this.context.manipulator[this.matrixTypePath] || 'square';
    }

    get rawMatrix() {
        return this.context.manipulator[this.context.property.path];
    }

    set rawMatrix(value) {
        this.context.manipulator[this.context.property.path] = value;
    }

    get rawMode() {
        return this.context.manipulator[this.matrixTypePath] || 'square';
    }

    set rawMode(value) {
        this.context.manipulator[this.matrixTypePath] = value;
    }

    get currentTypeLabel() {
        let type = this.currentType;
        let values = this.currentValue;

        if (type === 'square') {
            let square = Math.round(Math.sqrt(values.length));
            if (values.length === 0)
                square = this.squareSizes[0];
                
            return `${square}x${square}`;
        }

        if (type === 'row')
            return `Row (${values.length} wide)`;
        else if (type === 'column')
            return `Column (${values.length} tall)`;

        return 'Unknown';
    }

    startEdit() {
        this.editing = true;
        this.rowSize = this.currentValue.length || 1;
        this.values = this.currentValue;

        if (this.currentType === 'square') {
            if (this.values.length === 0) {
                this.selectedType = `${this.squareSizes[0]}`;
            } else {
                this.selectedType = `${Math.round(Math.sqrt(this.values.length))}`;
            }
        } else {
            this.selectedType = this.currentType;
        }
    }

    saveEdit() {
        let type = this.selectedType;
        if (!['row', 'column'].includes(this.selectedType))
            type = 'square';

        this.context.manipulator[this.matrixTypePath] = type;

        let values = this.values.slice(0, this.count);

        while (values.length < this.count)
            values.push(0);
        
        this.context.manipulator[this.context.property.path] = values.join(' ');
        this.editing = false;
    }

    discardEdit() {
        this.editing = false;
    }

    rowSize = 1;
    selectedType : string;
    values : number[] = [];

    get matrixTypePath() {
        return this.options.matrixTypePath;
    }

    get width() {
        if (['row', 'column'].includes(this.selectedType))
            return 4;

        return parseInt(this.selectedType);
    }

    editing = false;

    numericRange(min, max, step = 1) {
        let array = [];

        for (let i = min; i < max; i += step)
            array.push(i);

        return array;
    }

    get count() {
        if (['row', 'column'].includes(this.selectedType))
            return this.rowSize;

        return Math.pow(parseInt(this.selectedType), 2);
    }

    get options(): FFMatrixPropertyOptions {
        return this.context.property.typeOptions || {};
    }

    get squareSizes() {
        return this.options.squareSizes;
    }

    get allowSquare() {
        return this.options.squareSizes && this.options.squareSizes.length > 0;
    }

    get allowRow() {
        return this.options.maxRowSize && this.options.maxRowSize > 0;
    }

    get allowColumn() {
        return this.options.maxColumnSize && this.options.maxColumnSize > 0;
    }

    get maxRowSize() {
        return this.options.maxRowSize;
    }

    get maxColumnSize() {
        return this.options.maxColumnSize;
    }
}