import { Component, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Base64 } from 'js-base64';
import { BackendService, BackendSession } from '../backend.service';
import * as Hls from 'hls.js';

@Component({
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss']
})
export class PreviewComponent {
    constructor(
        private route : ActivatedRoute,
        private router : Router,
        private backendService : BackendService
    ) {
        
    }

    rawInput : string;
    input : string;
    type : string;

    backend : BackendSession;
    uiState = 'loading';

    @ViewChild('previewVideo')
    previewVideo : ElementRef<HTMLVideoElement>;
    
    async ngOnDestroy() {
        if (this.input && this.type && this.backend) {
            await this.backend.stopPreview(this.input, this.type);
        }
    }

    ngOnInit() {
        setTimeout(() => this.init(), 1000);
    }

    private init() {
        this.route.paramMap.subscribe(async params => {

            // Clean up any existing preview subscription
            if (this.input && this.type && this.backend) {
                await this.backend.stopPreview(this.input, this.type);
            }

            let serverID = params.get('server');

            this.backend = this.backendService.getSession(serverID);

            if (params.get('input'))
                this.rawInput =params.get('input');
            else
                this.rawInput = null;
            
            if (this.rawInput)
                this.input = Base64.fromBase64(this.rawInput);

            this.type = params.get('type');

            if (this.input && this.type) {
                this.uiState = 'starting';
                let preview = await this.backend.startPreview(this.input, this.type);
                let videoUrl = `${this.backend.baseUrl}${preview.previewUrl}`;
                this.uiState = 'waiting';

                let interval = null;

                interval = setInterval(async () => {

                    let videoResult = await fetch(videoUrl);

                    if (videoResult.status === 404) {
                        return;
                    }

                    clearInterval(interval);

                    this.uiState = 'playing';

                    let video = this.previewVideo.nativeElement;

                    if (Hls.isSupported()) {
                        var hls = new Hls();
                        hls.loadSource(videoUrl);
                        hls.attachMedia(video);
                        hls.on(Hls.Events.MANIFEST_PARSED, function() {
                            video.play();
                        });
                    }
                    else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                        video.src = videoUrl;
                        video.addEventListener('loadedmetadata', function() {
                            video.play();
                        });
                    }
                }, 1000);

            }
        });
    }
}