import { Component, HostBinding } from '@angular/core';
import { BackendService, BackendSession } from './backend.service';
import { Location } from '@angular/common';
import { Router, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SettingsService } from './settings.service';
import { MatDialog } from '@angular/material/dialog';
import { AboutComponent } from '@astronautlabs/chassis';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private backendService : BackendService,
    private location : Location,
    private router : Router,
    private titleService : Title,
    private settings : SettingsService,
    private matDialog : MatDialog
  ) {
    router.events.subscribe(ev => {
      if (ev instanceof NavigationStart) {
        this.web = ev.url.startsWith('/web');
      }
    });
  }

  backends : BackendSession[];
  
  get headerTheme() {
    return this.settings.config.headerTheme;
  }

  web = false;

  @HostBinding('class.mat-dark-theme')
  get darkTheme() {
    if (this.settings.config.theme === 'default') {
      if (matchMedia('(prefers-color-scheme: dark)')) {
        return true;
      } else {
        return false;
      }
    }

    return this.settings.config.theme === 'dark';
  }

  get currentBackendID() {
    if (!this.currentSession)
      return null;
    
    return this.currentSession.backend.id;
  }

  currentSession : BackendSession;

  showSignIn() {
    // TODO
  }

  showAbout() {
    this.matDialog.open(AboutComponent);
  }

  title : string;

  ngOnInit() {
    this.backendService.currentSessionChanged.subscribe(session => this.currentSession = session)

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.title = null;

        try {
          let match = event.url.match(/^\/servers\/([^\/]+)/);

          if (match) {
            let id = match[1];
            this.backendService.setCurrentSession(id);
            
            let topUrl = `/servers/${id}`
            let subUrl = event.url.substr(topUrl.length);

            if (subUrl.startsWith('/pipelines')) {
              this.title = 'Pipelines';
            } else if (subUrl.startsWith('/settings')) {
              this.title = 'Settings';
            } else if (subUrl.startsWith('/apps')) {
              this.title = 'Apps';
            }

          } else {
            this.backendService.setCurrentSession(null);
          }
        } catch (e) {
          console.error(`Caught error while handling navstart:`);
          console.error(e);
        }
      }
    });
    this.backendService.backendsChanged.subscribe(
      () => this.backends = this.backendService.allSessions()
    );
  }
}
