<article>
    <h1><img alt="About Livefire" src="/assets/logo.svg" 
        style="width: 100%;" /></h1>

    <div class="property-set">
        <div class="property">
            <label>Product</label>
            LiveFire
        </div>

        <div class="property">
            <label>Version</label>
            {{pkg.version}}
        </div>

        <div class="property">
            <label>Vendor</label>
            Astronaut Labs
        </div>

        <div class="property">
            <label>Package</label>
            {{pkg.name}}
        </div>
    </div>

    <br/>

    <div class="copyright">
        &copy; 2019-2020 Astronaut Labs
    </div>
</article>