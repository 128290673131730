import { Component, Optional, Inject } from "@angular/core";
import { Pipeline } from 'src/config';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService, BackendSession } from '../backend.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestartPipelineAfterSaveDialogComponent } from '../restart-pipeline-after-save-dialog.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import * as uuid from 'uuid/v4';
import { DiazoTypeBase } from 'diazo';
import { PIPELINE_TYPES } from '@astronautlabs/playout-server-diazo';

@Component({
    templateUrl: './pipeline-editor.component.html',
    styleUrls: ['./pipeline-editor.component.scss']
})
export class PipelineEditorComponent {
    constructor(
        private router : Router,
        private route : ActivatedRoute,
        private backendService : BackendService,
        private matDialog : MatDialog,

        @Optional()
        private matDialogRef : MatDialogRef<PipelineEditorComponent>,

        @Optional() @Inject(MAT_DIALOG_DATA) 
        public matDialogData: any
    ) {
        this.valueTypes = PIPELINE_TYPES.map(x => new x());
    }

    valueTypes : DiazoTypeBase[] = [];
    backend : BackendSession;

    ngOnInit() {

        if (this.matDialogData) {
            this.loadPipeline(this.matDialogData.serverID, this.matDialogData.id);
        } else {
            this.route.paramMap.subscribe(async params => {
                let id = params.get('id');
                let serverID = params.get('server');
                this.loadPipeline(serverID, id);
            });
        }
    }

    private async loadPipeline(serverID : string, id : string) {
        this.backend = this.backendService.getSession(serverID);

        if (id === 'new') {
            this.pipeline = {
                id: null,
                name: '',
                graph: {
                    nodes: [],
                    edges: []
                }
            }
        } else {
            this.pipeline = await this.backend.getPipeline(id);
            if (!this.pipeline) {
                this.router.navigateByUrl('/pipelines');
            }
        }

        this.fetchSession();
    }

    savedPipeline : Pipeline = null;
    pipeline : Pipeline = <any>{
        name: 'This Thing',
        outputs: []
    };

    async fetchSession() {
        if (this.pipeline.id && this.pipeline.id !== 'new') {
            this.session = await this.backend.getPipelineSession(this.pipeline.id);
        } else {
            this.session = null;
        }
    }

    session : any;

    reorderSlots(event) {
        moveItemInArray(this.pipeline.slots, event.previousIndex, event.currentIndex);
    }

    addSlot() {
        if (!this.pipeline.slots)
            this.pipeline.slots = [];
        
        this.pipeline.slots.push({
            id: uuid(),
            dynamic: false,
            label: '',
            type: 'input',
            value: {
                type: 'none',
                params: {}
            }
        });
    }

    async save(restart? : boolean) {

        if (!this.pipeline.id || this.pipeline.id === 'new') {
            // new 
            this.pipeline = await this.backend.createPipeline(this.pipeline);
        } else {
            if (restart === undefined) {
                let session = await this.backend.getPipelineSession(this.pipeline.id);
                if (session) {

                    let dialogRef = this.matDialog.open(RestartPipelineAfterSaveDialogComponent);
                    let result = await dialogRef.afterClosed().toPromise();

                    if (!result || result === 'cancel')
                        return;
                    
                    restart = (result === 'restart');
                }
            }

            if (restart === undefined)
                restart = false;
            
            try {
                this.pipeline = await this.backend.updatePipeline(this.pipeline, restart);
            } catch (e) {
                console.error(`Error while updating pipeline:`);
                console.error(e);
                alert(`Failed to update pipeline: ${e}`);
                return;
            }
        }

        //this.router.navigateByUrl(`/pipelines/${this.program.id}`);

        this.savedPipeline = this.pipeline;

        if (this.matDialogRef)
            this.matDialogRef.close();
    }

    get isPersisted() {
        return !!this.pipeline.id;
    }
    get isDialog() {
        return !!this.matDialogRef;
    }

    discard() {
        this.savedPipeline = null;
        if (this.matDialogRef)
            this.matDialogRef.close();
        else
            this.router.navigateByUrl(`/pipelines/${this.pipeline.id}`);
    }
}