
<header>
    <h1>Behold, the Power of Graphs</h1>
    <p>Media processing made easy</p>
</header>

<section>
    <h1>Stuff</h1>
    <p>Things and stuff that are important to the product.
        Know that things work as well as you could expect, and you should
        buy things.
    </p>
</section>

<section>
    <h1>More Stuff</h1>
    <p>{{product.name}} will even do More Stuff, and we guarantee that we do 
        More Stuff better than the competitors.
    </p>
</section>

<section>
    <h1>Even More Stuff</h1>
    <p>As if that weren't more than enough, {{product.name}} can even do 
        Even More Stuff, or your money back.
    </p>
</section>