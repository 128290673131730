import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Accessor } from 'diazo';
import { Base64 } from 'js-base64';
import { BehaviorSubject, Observable } from 'rxjs';

export interface HeaderTheme {
    id : string;
    backgroundColor : string;
    textColor : string;
    logoColor : string;
}
export interface Config {
    theme : string;
    editorTheme : string;
    graphTheme : string;
    headerTheme : HeaderTheme;
}

@Injectable()
export class SettingsService {
    constructor(
    ) {

        this.load();
    }

    load() {
        if (window.localStorage['livefire:settings']) {
            console.info(`Restoring previous settings...`);
            this.config = Object.assign(
                {}, 
                this.defaultConfig, 
                JSON.parse(Base64.decode(
                    window.localStorage['livefire:settings']
                ))
            );
        } else {
            this.config = Object.assign({}, this.defaultConfig);
        }

        this._configChanged.next(this.config);
    }

    save() {
        console.info(`Saving settings...`);
        window.localStorage['livefire:settings'] = Base64.encode(JSON.stringify(this.config));
        this._configChanged.next(this.config);
    }

    config : Config;

    private _configChanged = new BehaviorSubject<Config>(null);

    get configChanged(): Observable<Config> {
        return this._configChanged;
    }

    defaultConfig : Config = {
        theme: 'default',
        editorTheme: 'follow',
        graphTheme: 'follow',
        headerTheme: {
            id: 'default',
            textColor: '#ddd',
            backgroundColor: 'rgb(24, 24, 24)',
            logoColor: 'orange'
        }
    };

    get darkTheme() {
        if (!this.config)
            return true;
        
        if (this.config.theme === 'default') {
            if (matchMedia('(prefers-color-scheme: dark)')) {
                return true;
            } else {
                return false;
            }
        }

        return this.config.theme === 'dark';
    }

    get editorTheme() {
        if (!this.config)
            return 'vs-dark';

        if (this.config.editorTheme === 'follow') {
            if (this.darkTheme)
                return 'vs-dark';
            else
                return 'vs';
        }

        return this.config.editorTheme;
    }

    get graphTheme() {
        if (!this.config)
            return 'dark';

        if (this.config.graphTheme === 'follow') {
            if (this.darkTheme)
                return 'dark';
            else
                return 'light';
        }
    }
}