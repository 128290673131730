<ng-container *ngIf="!entry">
    <div class="loading-box">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>
<ng-container *ngIf="entry">
    <ng-container *ngIf="entry.id">
        <h1>Edit Input</h1>
    </ng-container>

    <div *ngIf="!input.type">
        <ng-container *ngIf="!entry.id">
            <h1>New Input</h1>
        </ng-container>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Type</mat-label>
            <mat-select [(ngModel)]="input.type">
                <mat-option value="rtmp">RTMP</mat-option>
                <mat-option value="udp">UDP</mat-option>
                <mat-option value="http">HTTP</mat-option>
                <mat-option value="file">File</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <ng-container *ngIf="input.type">
            
        <ng-container *ngIf="!entry.id">
            <h1>
                <a href="javascript:;" (click)="input.type = null">New Input</a>
                &raquo;
                {{input.type}}
            </h1>
        </ng-container>
        <div>
            <mat-form-field>
                <mat-label>Routing Path</mat-label>
                <input placeholder="/virtual/path" matInput type="text" [(ngModel)]="entry.path" />
            </mat-form-field>
        </div>
        <ng-container *ngIf="input.type === 'rtmp'">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>RTMP Endpoint</mat-label>
                <input placeholder="rtmp://hostname/appname/streamname" matInput type="text" [(ngModel)]="input.edge" />
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="input.type === 'udp'">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Port</mat-label>
                <input matInput type="number" [(ngModel)]="input.port" />
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="input.type === 'http'">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>HTTP Endpoint</mat-label>
                <input placeholder="https://example.com/index.m3u8" matInput type="text" [(ngModel)]="input.url" />
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="input.type === 'file'">
            <div>
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Filename</mat-label>
                    <input placeholder="/foo/bar.mp4" matInput type="text" [(ngModel)]="input.url" />
                </mat-form-field>
            </div>
        </ng-container>

    </ng-container>
    <div class="actions">
        <a mat-button (click)="cancel()">Cancel</a>
        <a mat-raised-button color="primary" (click)="save()" *ngIf="input.type" [disabled]="!valid">Save</a>
    </div>
</ng-container>